import { formatNumber } from '@/utils/numberUtils'
import { Link } from 'react-router-dom'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Asset } from '@/graphql/generated/graphql-request'
import { PRICE_DECIMALS } from '@/utils/constants'
import { parseAndFormatDate } from '@/utils/dateUtils'

interface BondDetailsDescriptionProps {
  baseAsset: Asset
  orderBookId: string | undefined
  top?: boolean
}

export const BondDetailsDescription = ({ baseAsset, orderBookId, top }: BondDetailsDescriptionProps) => {
  if (!baseAsset || !orderBookId) {
    return <div>No pair selected</div>
  }

  return (
    <div
      className={`w-full h-auto flex flex-col col-start-2 col-span-1 items-start gap-6 ${top ? 'justify-start' : 'justify-center'}`}
    >
      <div className={'flex flex-row gap-2 text-white text-2xl font-bold items-center'}>
        <div>
          <img
            src={'https://www.svgrepo.com/show/331345/coinbase-v2.svg'}
            className={'bg-dark-darker w-[36px] h-[36px] rounded-full'}
            alt={baseAsset?.symbol + ' logo'}
          />
        </div>
        <div>{baseAsset?.symbol ?? 'BOND'}</div>
        <div>
          {baseAsset?.bond?.coupon != null ? formatNumber(baseAsset?.bond?.coupon ?? 0, PRICE_DECIMALS) + '%' : 'n/a'}
        </div>
        <div>{baseAsset?.bond?.maturityDate ? parseAndFormatDate(baseAsset?.bond.maturityDate) : 'n/a'}</div>
      </div>
      <div className={'text-md text-white font-semibold'}>
        This is a bond issued by {baseAsset?.bond?.issuer ?? 'n/a'}. The bond pays{' '}
        {formatNumber(baseAsset?.bond?.coupon ?? 0, PRICE_DECIMALS)}% coupon to bond holders with interest in accruing
        daily. Interest is received either upon sell of the bond or every 6 months on a coupon date.
      </div>
      <div className={'text-md text-white-dark font-semibold'}>
        This pool - {orderBookId} allows you to purchase tokens representing equivalent value of this bond. You can earn
        yield by buying and holding these tokens OR you can look to profit from price movement by trading these tokens.
      </div>

      <div className={'w-full grid grid-rows-2 grid-cols-2 justify-start items-center gap-2'}>
        {baseAsset?.bond?.bondDetailsUrl && (
          <Link
            to={baseAsset?.bond?.bondDetailsUrl ?? '/'}
            className='w-auto row-start-1 col-start-1 flex flex-row items-center justify-between text-md text-center font-semibold uppercase rounded-md border border-white-dark p-2'
          >
            <div className='text-white'>Bond Details</div>
            <FaExternalLinkAlt size={12} />
          </Link>
        )}
        {baseAsset?.bond?.priceHistoryUrl && (
          <Link
            to={baseAsset?.bond?.priceHistoryUrl ?? '/'}
            className='w-auto row-start-1 col-start-2 flex flex-row items-center justify-between text-md text-center font-semibold uppercase rounded-md border border-white-dark p-2'
          >
            <div className='text-white'>Price History</div>
            <FaExternalLinkAlt size={12} />
          </Link>
        )}
        {baseAsset?.bond?.educationUrl && (
          <Link
            to={baseAsset?.bond?.educationUrl ?? '/'}
            className='w-auto row-start-2 col-start-1 flex flex-row items-center justify-between text-md text-center font-semibold uppercase rounded-md border border-white-dark p-2'
          >
            <div className='text-white'>Education</div>
            <FaExternalLinkAlt size={12} />
          </Link>
        )}
      </div>
    </div>
  )
}
