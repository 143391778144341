import { useEffect } from 'react'
import { wsClient } from '@/services/graphql'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'
import { Pool } from '@/graphql/generated/graphql-request'
import { queryClient } from '@/services/api'
import { POOLS_BALANCE_UPDATED } from '@/services/graphql/subscriptions/pools'

export const usePoolBalanceSubscription = () => {
  const user = useFirebaseAuth()
  useEffect(() => {
    const subscription = wsClient.subscribe(
      {
        operationName: 'PoolBalancesUpdated',
        query: POOLS_BALANCE_UPDATED,
      },
      {
        next: ({ data }: { data: { poolBalancesUpdated: Pool } | null }) => {
          if (!data) return
          if (data.poolBalancesUpdated) {
            queryClient.setQueryData<Pool[]>(['POOLS'], (oldPools) => {
              if (!oldPools) return [data.poolBalancesUpdated]

              const poolIndex = oldPools.findIndex((pool) => pool.uid === data.poolBalancesUpdated.uid)
              if (poolIndex > -1) {
                // Update the existing pool
                const updatedPools = [...oldPools]
                updatedPools[poolIndex] = data.poolBalancesUpdated
                return updatedPools
              } else {
                // Add the new pool
                return [...oldPools, data.poolBalancesUpdated]
              }
            })
          }
        },
        error: (err) => {
          console.log('usePoolBalanceSubscription subscription error', err)
        },
        complete: () => {},
      },
    )

    // Cleanup function to unsubscribe
    return () => {
      console.log('usePoolBalanceSubscription - Unsubscribing from pool balances subscription')
      subscription()
    }
  }, [user?.uid])
}
