import { useState, useEffect } from 'react'
import SolanaButton from '@/components/wallet/SolanaButton'
import { EthereumButton } from '@/components/wallet/EthereumButton'
import erc20Abi from '@/utils/abi/erc20.json'
import { depositAddress, USDC, USDCDecimals, USDCId } from '@/configs/tokens'
import { useAccount, useReadContracts, useTransactionReceipt, useWriteContract } from 'wagmi'
import { formatUnits } from 'viem'
import { useUserBalances } from '@/hooks/useUserBalances'
import { useAsset } from '@/hooks/useAsset'
import BridgeModal from './bridgeModal'
import { useMutation } from '@tanstack/react-query'
import { getSdk } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { BiX } from 'react-icons/bi'
import useUpdateUserWallets from '@/hooks/useUpdateUserWallets'

export const WalletButton = () => {
  const [open, setOpen] = useState(false)
  const [isDepositOpen, setIsDepositOpen] = useState(false)
  const [depositAmount, setDepositAmount] = useState('')

  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false)
  const [withdrawAmount, setWithdrawAmount] = useState('')

  const { isConnected, address, chain } = useAccount({})
  const { balances } = useUserBalances()

  useUpdateUserWallets()

  const doraUsdcBalanceResult = (balances?.available || []).find((b) => b.assetID === USDCId)

  const usdcBalanceResult: any = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: (USDC[chain?.id || ''] || USDC[1]).address as any,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [address],
      },
    ],
  })

  const [isDepositing, setIsDepositing] = useState(false)
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const { data: hash, writeContract, reset, error } = useWriteContract()
  const result = useTransactionReceipt({ hash })

  const withdrawMutation = useMutation({
    mutationFn: () =>
      getSdk(graphQLClient)
        .ExternalWithdraw({
          input: {
            chainID: chain?.id || 1,
            amount: String(Number(withdrawAmount) * 10 ** USDCDecimals),
            denom: USDCId,
            toHex: address || '',
          },
        })
        .then(() => {
          // params.selectedAsset, params.text, params.amount, params.isAdding
          createNotification(
            'Withdraw',
            `You have submitted request to withdraw ${withdrawAmount} USDC. Please allow for 30 minutes for the funds to appear on your wallet.`,
            NotificationType.SIMPLE,
          )
          reset()
          setIsWithdrawing(false)
          setIsWithdrawOpen(false)
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on ExternalWithdraw'
          createNotification('Error on ExternalWithdraw', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
          setIsWithdrawing(false)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['USER_BALANCES'] }).finally()
      usdcBalanceResult.refetch()
      setIsWithdrawing(false)
    },
  })

  const handleClose = () => {
    setOpen(false)
    console.log('handleClose')
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    if (open) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  useEffect(() => {
    if (!isDepositOpen) {
      setDepositAmount('')
      setIsDepositing(false)
    }
  }, [isDepositOpen])

  useEffect(() => {
    if (hash && !isDepositing && result.data?.status !== 'success') {
      const deposits = JSON.parse(window.localStorage.getItem('external-deposit') || '[]')
      window.localStorage.setItem(
        'external-deposit',
        JSON.stringify([
          ...deposits,
          {
            chainID: chain?.id || 1,
            txHex: hash as any,
            fromHex: address as any,
            toHex: depositAddress[chain?.id || 1],
            tokenContractHex: USDC[chain?.id || 1].address,
            amount: depositAmount,
          },
        ]),
      )
    }
  }, [hash, isDepositing, result.data?.status])

  return (
    <div>
      {open && (
        <div className={'relative'} onClick={handleClose}>
          <div className={'fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
            <div
              id={'wallet-modal'}
              className={
                'w-96 p-8 bg-panel flex flex-col gap-2 justify-center items-center border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
              }
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={handleClose} className='absolute top-6 right-6'>
                <BiX size={32} />
              </button>
              <div className={'text-white text-xl font-semibold mb-6'}>Wallet</div>
              <div className={'flex flex-col text-center'}>
                <EthereumButton
                  setIsDepositOpen={setIsDepositOpen}
                  setIsWithdrawOpen={setIsWithdrawOpen}
                  isConnected={isConnected}
                  address={address}
                  usdcBalanceResult={usdcBalanceResult}
                  doraUsdcBalanceResult={doraUsdcBalanceResult}
                />
              </div>
              <div className={'flex flex-col text-center mt-4'}>
                <SolanaButton />
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        type='button'
        onClick={() => setOpen(true)}
        className='btn bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
      >
        Wallet
      </button>
      {isDepositOpen && (
        <BridgeModal
          title='Deposit'
          onClose={() => setIsDepositOpen(false)}
          from={{
            chainId: chain?.id,
            chain: chain?.name || 'Ethereum',
            chainLogo: chain?.id === 1 ? '/assets/images/wallet/ethereum.svg' : '/assets/images/wallet/arbitrum.svg',
            asset: 'USD Coin',
            assetLogo: '/assets/images/tokens/usdc.svg',
            address,
          }}
          to={{
            chain: 'Dora',
            chainLogo: '/favicon.png',
            asset: 'USDC',
            assetLogo: `/assets/images/tokens/usdc.svg`,
          }}
          maxBalance={formatUnits(usdcBalanceResult?.data?.[0] || '', (USDC[chain?.id || 1] || USDC[1]).decimals)}
          amount={depositAmount}
          setAmount={setDepositAmount}
          buttonText={'Deposit'}
          loading={isDepositing}
          onSubmit={() => {
            writeContract({
              address: (USDC[chain?.id || 1] || USDC[1]).address as any,
              abi: erc20Abi,
              functionName: 'transfer',
              args: [
                depositAddress[chain?.id || 1] || depositAddress[1],
                Number(depositAmount) * 10 ** (USDC[chain?.id || 1] || USDC[1]).decimals,
              ],
            })
          }}
          isDeposit
          decimals={(USDC[chain?.id || 1] || USDC[1]).decimals}
        />
      )}
      {isWithdrawOpen && (
        <BridgeModal
          title='Withdraw'
          onClose={() => setIsWithdrawOpen(false)}
          from={{
            chain: 'Dora',
            chainLogo: '/favicon.png',
            asset: 'USDC',
            assetLogo: `/assets/images/tokens/usdc.svg`,
          }}
          to={{
            chainId: chain?.id,
            chain: chain?.name || 'Ethereum',
            chainLogo: chain?.id === 1 ? '/assets/images/wallet/ethereum.svg' : '/assets/images/wallet/arbitrum.svg',
            asset: 'USD Coin',
            assetLogo: '/assets/images/tokens/usdc.svg',
            address,
          }}
          maxBalance={formatUnits((doraUsdcBalanceResult?.amount || '') as any, USDCDecimals)}
          amount={withdrawAmount}
          setAmount={setWithdrawAmount}
          buttonText={'Withdraw'}
          loading={isWithdrawing}
          onSubmit={() => {
            setIsWithdrawing(true)
            withdrawMutation.mutate()
          }}
          isDeposit={false}
          decimals={USDCDecimals}
        />
      )}
    </div>
  )
}
