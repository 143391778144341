import { useAccount } from 'wagmi'
import { useUser } from './useUser'
import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { getSdk } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { queryClient } from '@/services/api'

const useUpdateUserWallets = () => {
  const { address, chain } = useAccount({})
  const { user } = useUser()

  const connectedWallet = user?.wallets?.find(
    (w) => w?.chainID === String(chain?.id) && w?.address.includes(address as any),
  )

  const updateMutation = useMutation({
    mutationFn: () =>
      getSdk(graphQLClient).UpdateUser({
        updatedUser: {
          uid: user.uid,
          wallets: (user.wallets || []).find((w) => w?.chainID === String(chain?.id))
            ? (user.wallets || []).map((w) => {
                return {
                  chainID: w?.chainID || '',
                  address: w?.chainID === String(chain?.id) ? [...(w?.address || []), address || ''] : w?.address || [],
                }
              })
            : ([
                ...(user.wallets || []),
                {
                  chainID: String(chain?.id || ''),
                  address: [address || ''],
                },
              ] as any),
        },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] }).finally()
    },
  })

  useEffect(() => {
    if (address && chain?.id && !connectedWallet) {
      updateMutation.mutate()
    }
  }, [address, chain?.id, !!connectedWallet])
}

export default useUpdateUserWallets
