import { FormEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { removeLettersForInput } from '@/utils/inputUtils'
import { useMutation } from '@tanstack/react-query'
import {
  Asset,
  AssetBondType,
  AssetType,
  AssetUsage,
  BondInput,
  CouponFrequency,
  getSdk,
} from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { removeFormatNumber } from '@/utils/numberUtils'
import { BondForm } from '@/components/administration/BondForm'
import { MultiSelect } from '@/components/MultiSelection'
import dayjs from 'dayjs'

// Define the options for the MultiSelect
const assetTypeOptions = Object.values(AssetUsage).map((type) => ({
  label: type,
  value: type,
}))

const validationSchema = z.object({
  couponStartDate: z.string().optional(),
  finalCouponDate: z.string().optional(),
  isin: z.string().optional(),
  issueDate: z.string().optional(),
  issuer: z.string().optional(),
  maturityDate: z.string().optional(),
  name: z.string().optional(),
  numberOfPaymentsPerYear: z.string().optional(),
  yield: z.string().optional(),
  decimals: z.string(),
  description: z.string().min(1, 'Please insert a description'),
  symbol: z.string().min(1, 'Please insert a symbol'),
  type: z.enum([AssetType.Bond, AssetType.Currency, AssetType.PoolShare]),
  usage: z.array(
    z.enum([
      AssetUsage.Trade,
      AssetUsage.DirectBorrow,
      AssetUsage.VirtualBorrow,
      AssetUsage.PoolShare,
      AssetUsage.Onboard,
    ]),
  ),
  collateralWeight: z.string().min(1, 'Please insert a collateral weight'),
  liquidationThreshold: z.string().min(1, 'Please insert a liquidation threshold'),
  maxUtilization: z.string().min(1, 'Please insert a max utilization'),
  maxSupply: z.string().min(1, 'Please insert a max supply'),
  assetBondType: z.nativeEnum(AssetBondType).optional(),
  coupon: z.string().optional(),
  couponFrequency: z.nativeEnum(CouponFrequency).optional(),
  cusip: z.string().optional(),
  ticker: z.string().optional(),
  bondDetailsUrl: z.string().optional(),
  priceHistoryUrl: z.string().optional(),
  educationUrl: z.string().optional(),
})

export type ValidationSchema = z.infer<typeof validationSchema>

interface CreateAssetFormProps {
  asset?: Asset
}

export const CreateOrUpdateAsset = ({ asset }: CreateAssetFormProps) => {
  const [selectedType, setSelectedType] = useState<string>(asset ? asset.type : AssetType.Bond)
  const [selectedUsage, setSelectedUsage] = useState<string[]>(asset ? asset.usage : [])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  })

  useEffect(() => {
    if (!asset) return
    // Set the form values when the asset is passed
    const formatDate = (date: string | undefined) => (date ? dayjs(date).format('YYYY-MM-DD') : '')

    const setFormValue = (field: keyof ValidationSchema, value: any) => {
      if (value !== undefined && value !== null) {
        setValue(field, value)
      }
    }

    setFormValue('decimals', asset.decimals.toString())
    setFormValue('description', asset.description)
    setFormValue('symbol', asset.symbol)
    setFormValue('type', asset.type)
    setFormValue('usage', asset.usage)
    setFormValue('collateralWeight', asset.collateralWeight)
    setFormValue('liquidationThreshold', asset.liquidationThreshold)
    setFormValue('maxUtilization', asset.maxUtilization)
    setFormValue('maxSupply', asset.maxSupply)
    setFormValue('issuer', asset.bond?.issuer)
    setFormValue('isin', asset.bond?.isin)
    setFormValue('name', asset.bond?.name)
    setFormValue('numberOfPaymentsPerYear', asset.bond?.numberOfPaymentsPerYear?.toString())
    setFormValue('yield', asset.bond?.yield?.toString())
    setFormValue('assetBondType', asset.bond?.assetBondType)
    setFormValue('coupon', asset.bond?.coupon?.toString())
    setFormValue('couponFrequency', asset.bond?.couponFrequency)
    setFormValue('cusip', asset.bond?.cusip)
    setFormValue('ticker', asset.bond?.ticker)
    setFormValue('bondDetailsUrl', asset.bond?.bondDetailsUrl)
    setFormValue('priceHistoryUrl', asset.bond?.priceHistoryUrl)
    setFormValue('educationUrl', asset.bond?.educationUrl)
    setFormValue('maturityDate', formatDate(asset.bond?.maturityDate))
    setFormValue('couponStartDate', formatDate(asset.bond?.couponStartDate))
    setFormValue('finalCouponDate', formatDate(asset.bond?.finalCouponDate))
    setFormValue('issueDate', formatDate(asset.bond?.issueDate))
  }, [asset])

  const handleInputChange = (e: FormEvent<HTMLInputElement>, field: keyof ValidationSchema, decimals: number = 2) => {
    const inputValue = e.currentTarget.value
    const sanitizedInput = removeLettersForInput(inputValue, decimals)
    setValue(field, sanitizedInput)
  }

  const createAssetMutation = useMutation({
    mutationFn: ({
      decimals,
      description,
      symbol,
      type,
      usage,
      collateralWeight,
      liquidationThreshold,
      maxUtilization,
      maxSupply,
      bond,
    }: {
      decimals: number
      description: string
      symbol: string
      type: AssetType
      usage: AssetUsage[]
      collateralWeight: string
      liquidationThreshold: string
      maxUtilization: string
      maxSupply: string
      bond: BondInput | null
    }) =>
      getSdk(graphQLClient)
        .CreateAsset({
          newAsset: {
            decimals: decimals,
            description: description,
            symbol: symbol,
            type: type,
            usage: usage,
            collateralWeight: collateralWeight,
            liquidationThreshold: liquidationThreshold,
            maxUtilization: maxUtilization,
            maxSupply: maxSupply,
            bond: bond,
          },
        })
        .then(() => {
          createNotification('Create Asset ', { symbol: symbol }, NotificationType.CREATE_ASSET)
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on CreateAsset'
          createNotification('Error on CreateAsset', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ASSETS'] }).finally()
    },
  })

  const updateAssetMutation = useMutation({
    mutationFn: ({
      description,
      type,
      usage,
      collateralWeight,
      liquidationThreshold,
      maxUtilization,
      maxSupply,
      bond,
    }: {
      decimals: number
      description: string
      symbol: string
      type: AssetType
      usage: AssetUsage[]
      collateralWeight: string
      liquidationThreshold: string
      maxUtilization: string
      maxSupply: string
      bond: BondInput | null
    }) =>
      getSdk(graphQLClient)
        .UpdateAsset({
          updateAsset: {
            assetID: asset?.uid ?? '',
            bond: bond,
            collateralWeight: collateralWeight,
            description: description,
            liquidationThreshold: liquidationThreshold,
            maxSupply: maxSupply,
            maxUtilization: maxUtilization,
            type: type,
            usage: usage,
          },
        })
        .then(() => {
          createNotification('Asset Update ', 'Asset update was successful', NotificationType.SIMPLE)
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on UpdateAsset'
          createNotification('Error on UpdateAsset', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ASSETS'] }).finally()
    },
  })

  const submit = async (data: ValidationSchema) => {
    // Sai Kumar:
    // if the values are not present or not given by user , Please add values as null
    const toNullIfEmpty = (value: string | undefined) => (value?.trim() === '' ? null : value)

    const payload = {
      decimals: Number(removeFormatNumber(data.decimals)),
      description: toNullIfEmpty(data.description),
      symbol: toNullIfEmpty(data.symbol),
      type: data.type,
      usage: data.usage,
      collateralWeight: toNullIfEmpty(removeFormatNumber(data.collateralWeight)),
      liquidationThreshold: toNullIfEmpty(removeFormatNumber(data.liquidationThreshold)),
      maxUtilization: toNullIfEmpty(removeFormatNumber(data.maxUtilization)),
      maxSupply: toNullIfEmpty(removeFormatNumber(data.maxSupply)),
      bond:
        data.type === AssetType.Bond
          ? ({
              issuer: toNullIfEmpty(data.issuer),
              couponStartDate: toNullIfEmpty(data.couponStartDate),
              isin: toNullIfEmpty(data.isin),
              finalCouponDate: toNullIfEmpty(data.finalCouponDate),
              maturityDate: toNullIfEmpty(data.maturityDate),
              issueDate: toNullIfEmpty(data.issueDate),
              name: toNullIfEmpty(data.name),
              numberOfPaymentsPerYear: Number(removeFormatNumber(data.numberOfPaymentsPerYear ?? '0')),
              yield: Number(removeFormatNumber(data.yield ?? '0')),
              assetBondType: data.assetBondType,
              coupon: Number(removeFormatNumber(data.coupon ?? '0')),
              couponFrequency: data.couponFrequency,
              cusip: toNullIfEmpty(data.cusip),
              ticker: toNullIfEmpty(data.ticker),
              bondDetailsUrl: toNullIfEmpty(data.bondDetailsUrl),
              priceHistoryUrl: toNullIfEmpty(data.priceHistoryUrl),
              educationUrl: toNullIfEmpty(data.educationUrl),
            } as BondInput)
          : null,
    }

    if (asset) {
      // @ts-ignore
      updateAssetMutation.mutate(payload)
    } else {
      // @ts-ignore
      createAssetMutation.mutate(payload)
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className='w-full'>
      <div>
        <div className='w-full h-full flex flex-row gap-2 justify-start items-start'>
          <div className='w-full max-w-md panel dark:bg-card flex flex-col h-full'>
            <div className='grid grid-cols-2 grid-rows-6 gap-x-2'>
              <div className=' flex flex-col col-start-1 row-start-1'>
                <div className='flex flex-col w-full'>
                  <div className='py-1 whitespace-nowrap text-left'>Collateral Weight</div>
                  <input
                    id={'collateralWeight'}
                    {...register('collateralWeight', { required: true })}
                    onInput={(e) => handleInputChange(e, 'collateralWeight')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>

              <div className=' flex flex-col col-start-2 row-start-1'>
                <div className=' flex flex-col w-full'>
                  <div className='py-1 whitespace-nowrap text-left'>Decimals</div>
                  <input
                    id={'decimals'}
                    {...register('decimals', { required: true })}
                    onInput={(e) => handleInputChange(e, 'decimals')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>

              <div className=' flex flex-col col-start-1 row-start-2 col-span-2'>
                <div className=' flex flex-col w-full'>
                  <div className='py-1 whitespace-nowrap text-left'>Description</div>
                  <input
                    id={'description'}
                    {...register('description', { required: true })}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>

              <div className=' flex flex-col col-start-1 row-start-3'>
                <div className=' flex flex-col w-full'>
                  <div className='py-1 whitespace-nowrap text-left'>Max Supply</div>
                  <input
                    id={'maxSupply'}
                    {...register('maxSupply', { required: true })}
                    onInput={(e) => handleInputChange(e, 'maxSupply')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>

              <div className=' flex flex-col col-start-2 row-start-3'>
                <div className=' flex flex-col w-full'>
                  <div className='py-1 whitespace-nowrap text-left'>Max Utilization</div>
                  <input
                    id={'maxUtilization'}
                    {...register('maxUtilization', { required: true })}
                    onInput={(e) => handleInputChange(e, 'maxUtilization')}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>

              <div className=' flex flex-col col-start-1 row-start-4'>
                <div className=' flex flex-col w-full'>
                  <div className='py-1 whitespace-nowrap text-left'>Symbol</div>
                  <input
                    id={'symbol'}
                    {...register('symbol', { required: true })}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>

              <div className=' flex flex-col col-start-2 row-start-4'>
                <div className=' flex flex-col w-full '>
                  <div className='py-1 whitespace-nowrap text-left'>Type</div>
                  <select
                    {...register('type', { required: true })}
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                    className='relative rounded-sm shadow-sm appearance-none form-input py-1 peer placeholder:tracking-widest border valid:border-dark text-right outline-none focus:ring-0 focus:ring-opacity-0'
                  >
                    {Object.values(AssetType).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className=' flex flex-col col-start-1 col-span-2 row-start-5'>
                <div className=' flex flex-col w-full '>
                  <div className='py-1 whitespace-nowrap text-left'>Usage</div>
                  <MultiSelect
                    options={assetTypeOptions}
                    onValueChange={(values) => {
                      setSelectedUsage(values)
                      setValue('usage', values as AssetUsage[]) // Update the form value
                    }}
                    defaultValue={selectedUsage}
                    placeholder='Select Asset Types'
                  />
                </div>
              </div>

              <div className=' flex flex-col col-start-1 row-start-6'>
                <div className=' flex flex-col w-full'>
                  <div className='py-1 whitespace-nowrap text-left mt-2'>Liquidation Threshold</div>
                  <input
                    id={'liquidationThreshold'}
                    {...register('liquidationThreshold', { required: true })}
                    className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col'>
              <button
                type='submit'
                className='btn mt-2 bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
              >
                {asset ? 'Update Asset' : 'Create Asset'}
              </button>
            </div>
          </div>

          {selectedType === AssetType.Bond && (
            <div>
              <BondForm
                errors={errors}
                register={register}
                handleInputChange={handleInputChange}
                defaultValues={getValues()}
              />
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
