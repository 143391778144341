import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  LanguageCode,
  ResolutionString,
  widget,
  ChartingLibraryWidgetOptions,
  StudyEventType,
  EntityInfo,
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '@/tradingview/charting_library'
import type { TvWidget } from '@/constants/tvchart'
import { AMOUNT_DECIMALS, DEFAULT_RESOLUTION, PRICE_DECIMALS } from '@/utils/constants'
import { AppColorMode, AppTheme } from '@/hooks/tradingView/useTradingViewTheme'
import {
  getSupportedTimezone,
  getWidgetOptions,
  getWidgetOverrides,
  loadStudies,
  saveStudies,
} from '@/utils/tradingview'
import { getDatafeed } from '@/hooks/tradingView/dataFeed'
import { useAllAssets } from '@/hooks/useAsset'
import { useOrderBooks } from '@/hooks/useOrderBooks'
import { useOrderBookID } from '@/hooks/useOrderBookID'
import { useUserOrders } from '@/hooks/useUserOrders'
import { getOrderTypeName } from '@/utils/tradeUtils'
import { formatNumber } from '@/utils/numberUtils'
import { Asset, Order, OrderType } from '@/graphql/generated/graphql-request'

/**
 * @description Hook to initialize TradingView Chart
 */
export const useTradingView = ({
  tvWidgetRef,
  displayButtonRef,
  setIsChartReady,
  setShowBondDetails,
}: {
  tvWidgetRef: MutableRefObject<TvWidget | null>
  displayButtonRef: MutableRefObject<HTMLElement | null>
  setIsChartReady: Dispatch<SetStateAction<boolean>>
  setShowBondDetails: Dispatch<SetStateAction<boolean>>
}) => {
  const appTheme = AppTheme.Dark
  const appColorMode = AppColorMode.GreenUp
  const [initialPriceScale, setInitialPriceScale] = useState<number | null>(null)
  const { allAssets } = useAllAssets()
  const { orderBooks } = useOrderBooks()
  const selectedLocale = 'en' as LanguageCode
  const { orderBookId } = useOrderBookID()
  const { openOrders } = useUserOrders(orderBookId)
  const [isChartReady, setChartReady] = useState(false)
  const [chartLines, setChartLines] = useState<IOrderLineAdapter[]>([])

  const marketIds = useMemo(() => orderBooks.map((orderbook) => orderbook.orderBookID), [orderBooks.length])

  const priceDisplayMultiplier = useMemo(
    () => orderBooks.find((orderbook) => orderbook.orderBookID === orderBookId)?.priceDisplayMultiplier || 1,
    [orderBooks.length],
  )
  const hasMarkets = marketIds.length > 0
  const currentMarket = orderBooks.find((orderbook) => orderbook.orderBookID === orderBookId)
  const marketId = currentMarket?.orderBookID
  const tickSizeDecimals = PRICE_DECIMALS

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const supportedTimezone = getSupportedTimezone(timezone) || 'Etc/UTC'

  const tvChartWidgetRef = useRef<IChartingLibraryWidget | null>(null)

  useEffect(() => {
    console.log('supportedTimezone::::::::', supportedTimezone)
    if (hasMarkets && marketId) {
      const widgetOptions = getWidgetOptions()
      const widgetOverrides = getWidgetOverrides({ appTheme, appColorMode })
      const options = {
        ...widgetOptions,
        ...widgetOverrides,
        datafeed: getDatafeed(initialPriceScale, tickSizeDecimals, allAssets, orderBookId),
        interval: DEFAULT_RESOLUTION as ResolutionString,
        locale: selectedLocale,
        symbol: marketId,
        timezone: supportedTimezone,
      }

      const tvChartWidget = new widget(options as ChartingLibraryWidgetOptions)
      tvChartWidgetRef.current = tvChartWidget
      tvChartWidget.onChartReady(() => {
        setChartReady(true)
        const storedStudies = loadStudies(marketId)

        storedStudies.forEach((study: EntityInfo) => {
          tvChartWidget
            .activeChart()
            .createStudy(study.name, false, false)
            .catch((e) => {
              console.error('Failed to add study', e)
            })
        })

        tvChartWidget.subscribe('study_event', (event, studyEventType: StudyEventType) => {
          const studies = tvChartWidget.activeChart().getAllStudies()
          saveStudies(marketId, studies)
        })

        tvChartWidget.headerReady().then(() => {
          const button = tvChartWidget.createButton()
          button.addEventListener('click', () => {
            setShowBondDetails(true)
          })
          button.innerHTML = 'Bond Details'
          setIsChartReady(true)
        })
      })
    }

    return () => {
      displayButtonRef.current?.remove()
      displayButtonRef.current = null
      tvWidgetRef.current?.remove()
      tvWidgetRef.current = null
      setIsChartReady(false)
    }
  }, [hasMarkets, selectedLocale, !!marketId, orderBookId])

  const createOpenOrderLine = useCallback(() => {
    try {
      if (!isChartReady || !tvChartWidgetRef.current) return
      // Remove all existing order lines
      chartLines.forEach((line) => {
        try {
          line.remove()
        } catch (e) {}
      })

      openOrders.map((order: Order) => {
        const orderType = getOrderTypeName(order.type)
        const decimalsOut = allAssets.find((asset) => asset.uid === order.assetOutID)?.decimals || 0
        const amountOutNumber = Number.isNaN(order.amountOut) ? 0 : Number(order.amountOut)
        const amountOut = formatNumber(amountOutNumber / Math.pow(10, decimalsOut), AMOUNT_DECIMALS)
        const lineColor =
          order.type === OrderType.LimitBuy || order.type === OrderType.MarketBuy ? '#3ED9A4' : '#E45555'

        if (tvChartWidgetRef.current) {
          const line = tvChartWidgetRef.current
            .activeChart()
            .createOrderLine()
            .setTooltip(order.orderID)
            .setLineColor(lineColor)
            .setBodyBackgroundColor('#000000')
            .setBodyBorderColor(lineColor)
            .setQuantityBackgroundColor(lineColor)
            .setQuantityBorderColor(lineColor)
            .setPrice(order.price * priceDisplayMultiplier)
            .setText(orderType)
            .setBodyTextColor('#ffffff')
            .setQuantity(amountOut)

          //store the line
          setChartLines((prev) => [...prev, line])
        }
        {
        }
      })
    } catch (error) {
      console.log('createOpenOrderLine Error:::::::', error)
    }
  }, [openOrders, allAssets, isChartReady])

  useEffect(() => {
    if (isChartReady) {
      createOpenOrderLine()
    }
  }, [JSON.stringify(openOrders), isChartReady])
  return {}
}
