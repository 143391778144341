import { type TooltipStrings } from '@/constants/localization'

export const tradeTooltips: TooltipStrings = {
  'account-leverage': () => ({
    title: 'title_account-leverage', //stringGetter({ key: TOOLTIP_STRING_KEYS.ACCOUNT_LEVERAGE_TITLE }),
    body: 'body_account-leverage', //stringGetter({ key: TOOLTIP_STRING_KEYS.ACCOUNT_LEVERAGE_BODY }),
  }),
  'base-position-notional': () => ({
    title: 'title_base-position-notional', //stringGetter({ key: TOOLTIP_STRING_KEYS.BASE_POSITION_NOTIONAL_TITLE }),
    body: 'body_base-position-notional', //stringGetter({ key: TOOLTIP_STRING_KEYS.BASE_POSITION_NOTIONAL_BODY }),
  }),
  'bracket-sl': () => ({
    title: 'title_bracket-sl', //stringGetter({ key: TOOLTIP_STRING_KEYS.BRACKET_ORDER_SL_TITLE }),
    body: 'body_bracket-sl', //stringGetter({ key: TOOLTIP_STRING_KEYS.BRACKET_ORDER_SL_BODY }),
  }),
  'bracket-tp': () => ({
    title: 'title_bracket-tp', //stringGetter({ key: TOOLTIP_STRING_KEYS.BRACKET_ORDER_TP_TITLE }),
    body: 'body_bracket-tp', //stringGetter({ key: TOOLTIP_STRING_KEYS.BRACKET_ORDER_TP_BODY }),
  }),
  'buying-power': ({ stringGetter, stringParams }) => ({
    title: 'title_buying-power', //stringGetter({ key: TOOLTIP_STRING_KEYS.BUYING_POWER_TITLE }),
    body: 'body_buying-power', //stringGetter({ key: TOOLTIP_STRING_KEYS.BUYING_POWER_BODY, params: stringParams }),
  }),
  'default-execution': () => ({
    title: 'title_default-execution', //stringGetter({ key: TOOLTIP_STRING_KEYS.DEFAULT_EXECUTION_TITLE }),
    body: 'body_default-execution', //stringGetter({ key: TOOLTIP_STRING_KEYS.DEFAULT_EXECUTION_BODY }),
  }),
  equity: () => ({
    title: 'title_equity', //stringGetter({ key: TOOLTIP_STRING_KEYS.EQUITY_TITLE }),
    body: 'body_equity', //stringGetter({ key: TOOLTIP_STRING_KEYS.EQUITY_BODY }),
  }),
  'expected-price': () => ({
    title: 'Expected Price', //stringGetter({ key: TOOLTIP_STRING_KEYS.EXPECTED_PRICE_TITLE }),
    body: 'Expected price of execution for your order. This price is only an estimate as the orderbook may change before your order is processed by the network.', //stringGetter({ key: TOOLTIP_STRING_KEYS.EXPECTED_PRICE_BODY }),
  }),
  fee: () => ({
    title: 'Trading Fees', //stringGetter({ key: TOOLTIP_STRING_KEYS.FEE_TITLE }),
    body: 'Trading Fees are charged by the network based on a percent of your trading volume.', //stringGetter({ key: TOOLTIP_STRING_KEYS.FEE_BODY }),
  }),
  'fill-or-kill': () => ({
    title: 'title_fill-or-kill', //stringGetter({ key: TOOLTIP_STRING_KEYS.FILL_OR_KILL_TITLE }),
    body: 'body_fill-or-kill', //stringGetter({ key: TOOLTIP_STRING_KEYS.FILL_OR_KILL_BODY }),
  }),
  'free-collateral': () => ({
    title: 'title_free-collateral', //stringGetter({ key: TOOLTIP_STRING_KEYS.FREE_COLLATERAL_TITLE }),
    body: 'body_free-collateral', //stringGetter({ key: TOOLTIP_STRING_KEYS.FREE_COLLATERAL_BODY }),
  }),
  'good-til': () => ({
    title: 'title_good-til', //stringGetter({ key: TOOLTIP_STRING_KEYS.GOOD_TIL_TITLE }),
    body: 'body_good-til', //stringGetter({ key: TOOLTIP_STRING_KEYS.GOOD_TIL_BODY }),
  }),
  'immediate-or-cancel': () => ({
    title: 'title_immediate-or-cancel', //stringGetter({ key: TOOLTIP_STRING_KEYS.IMMEDIATE_OR_CANCEL_TITLE }),
    body: 'body_immediate-or-cancel', //stringGetter({ key: TOOLTIP_STRING_KEYS.IMMEDIATE_OR_CANCEL_BODY }),
  }),
  'index-price': () => ({
    title: 'title_index-price', //stringGetter({ key: TOOLTIP_STRING_KEYS.INDEX_PRICE_TITLE }),
    body: 'body_index-price', //stringGetter({ key: TOOLTIP_STRING_KEYS.INDEX_PRICE_BODY }),
  }),
  'initial-margin-fraction': ({ stringGetter, urlConfigs }) => ({
    title: 'title_initial-margin-fraction', //stringGetter({ key: TOOLTIP_STRING_KEYS.INITIAL_MARGIN_FRACTION_TITLE }),
    body: 'body_initial-margin-fraction', //stringGetter({ key: TOOLTIP_STRING_KEYS.INITIAL_MARGIN_FRACTION_BODY }),
    learnMoreLink: urlConfigs?.initialMarginFractionLearnMore,
  }),
  'initial-stop': () => ({
    title: 'title_initial-stop', //stringGetter({ key: TOOLTIP_STRING_KEYS.INITIAL_STOP_TITLE }),
    body: 'body_initial-stop', //stringGetter({ key: TOOLTIP_STRING_KEYS.INITIAL_STOP_BODY }),
  }),
  leverage: () => ({
    title: 'Leverage',
    body: 'Leverage allows you to trade with borrowed funds, increasing your potential returns and risk. It is expressed as a ratio, such as 10:1, indicating the amount of exposure you have relative to your own capital.',
  }),
  'cancel-order': () => ({
    title: 'Cancel Order',
    body: 'This order will be canceled if it has not been filled by the time it expires.',
  }),
  'limit-price': () => ({
    title: 'Limit Price', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIMIT_PRICE_TITLE }),
    body: 'This order can only be filled at the specified limit price or better. If your order crosses at the time of placement, your order will fill any crossing orders at the most favorable price.', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIMIT_PRICE_BODY }),
  }),
  'limit-price-slippage': () => ({
    title: 'title_limit-price-slippage', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIMIT_PRICE_SLIPPAGE_TITLE }),
    body: 'body_limit-price-slippage', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIMIT_PRICE_SLIPPAGE_BODY }),
  }),
  'liquidation-price': ({ stringGetter, stringParams }) => ({
    title: 'title_liquidation-price', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIQUIDATION_PRICE_TITLE }),
    body: 'body_liquidation-price', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIQUIDATION_PRICE_BODY, params: stringParams }),
  }),
  'liquidation-price-general': () => ({
    title: 'title_liquidation-price-general', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIQUIDATION_PRICE_GENERAL_TITLE }),
    body: 'body_liquidation-price-general', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIQUIDATION_PRICE_GENERAL_BODY }),
  }),
  liquidity: () => ({
    title: 'title_liquidity', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIQUIDITY_TITLE }),
    body: 'body_liquidity', //stringGetter({ key: TOOLTIP_STRING_KEYS.LIQUIDITY_BODY }),
  }),
  'maintenance-margin-fraction': () => ({
    title: 'title_maintenance-margin-fraction', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAINTENANCE_MARGIN_FRACTION_TITLE }),
    body: 'body_maintenance-margin-fraction', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAINTENANCE_MARGIN_FRACTION_BODY }),
  }),
  'max-reward': () => ({
    title: 'title_max-reward', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAXIMUM_REWARDS_TITLE }),
    body: 'body_max-reward', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAXIMUM_REWARDS_BODY }),
  }),
  'max-withdraw': () => ({
    title: 'title_max-withdraw', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAX_WITHDRAW_TITLE }),
    body: 'body_max-withdraw', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAX_WITHDRAW_BODY }),
  }),
  'maker-fee': () => ({
    title: 'title_maker-fee', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAKER_FEE_TITLE }),
    body: 'body_maker-fee', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAKER_FEE_BODY }),
  }),
  'margin-usage': () => ({
    title: 'title_margin-usage', //stringGetter({ key: TOOLTIP_STRING_KEYS.MARGIN_USAGE_TITLE }),
    body: 'body_margin-usage', //stringGetter({ key: TOOLTIP_STRING_KEYS.MARGIN_USAGE_BODY }),
  }),
  'maximum-leverage': () => ({
    title: 'title_maximum-leverage', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAXIMUM_LEVERAGE_TITLE }),
    body: 'body_maximum-leverage', //stringGetter({ key: TOOLTIP_STRING_KEYS.MAXIMUM_LEVERAGE_BODY }),
  }),
  'net-funding': () => ({
    title: 'title_net-funding', //stringGetter({ key: TOOLTIP_STRING_KEYS.NET_FUNDING_TITLE }),
    body: 'body_net-funding', //stringGetter({ key: TOOLTIP_STRING_KEYS.NET_FUNDING_BODY }),
  }),
  'open-interest': () => ({
    title: 'title_open-interest', //stringGetter({ key: TOOLTIP_STRING_KEYS.OPEN_INTEREST_TITLE }),
    body: 'body_open-interest', //stringGetter({ key: TOOLTIP_STRING_KEYS.OPEN_INTEREST_BODY }),
  }),
  'oracle-price': () => ({
    title: 'title_oracle-price', //stringGetter({ key: TOOLTIP_STRING_KEYS.ORACLE_PRICE_TITLE }),
    body: 'body_oracle-price', //stringGetter({ key: TOOLTIP_STRING_KEYS.ORACLE_PRICE_BODY }),
  }),
  'order-amount': ({ stringGetter, stringParams }) => ({
    title: 'title_order-amount', //stringGetter({ key: TOOLTIP_STRING_KEYS.ORDER_AMOUNT_TITLE }),
    body: 'body_order-amount', //stringGetter({ key: TOOLTIP_STRING_KEYS.ORDER_AMOUNT_BODY, params: stringParams }),
  }),
  'order-amount-usd': ({ stringGetter, stringParams }) => ({
    title: 'title_order-amount-usd', //stringGetter({ key: TOOLTIP_STRING_KEYS.ORDER_AMOUNT_USD_TITLE }),
    body: 'body_order-amount-usd', //stringGetter({ key: TOOLTIP_STRING_KEYS.ORDER_AMOUNT_USD_BODY, params: stringParams }),
  }),
  'post-only': () => ({
    title: 'title_post-only', //stringGetter({ key: TOOLTIP_STRING_KEYS.POST_ONLY_TITLE }),
    body: 'body_post-only', //stringGetter({ key: TOOLTIP_STRING_KEYS.POST_ONLY_BODY }),
  }),
  'price-impact': () => ({
    title: 'title_price-impact', //stringGetter({ key: TOOLTIP_STRING_KEYS.PRICE_IMPACT_TITLE }),
    body: 'body_price-impact', //stringGetter({ key: TOOLTIP_STRING_KEYS.PRICE_IMPACT_BODY }),
  }),
  'realized-pnl': () => ({
    title: 'title_realized-pnl', //stringGetter({ key: TOOLTIP_STRING_KEYS.REALIZED_PNL_TITLE }),
    body: 'body_realized-pnl', //stringGetter({ key: TOOLTIP_STRING_KEYS.REALIZED_PNL_BODY }),
  }),
  'reduce-only': ({ stringGetter, urlConfigs }) => ({
    title: 'title_reduce-only', //stringGetter({ key: TOOLTIP_STRING_KEYS.REDUCE_ONLY_TITLE }),
    body: 'body_reduce-only', //stringGetter({ key: TOOLTIP_STRING_KEYS.REDUCE_ONLY_BODY }),
    learnMoreLink: urlConfigs?.reduceOnlyLearnMore,
  }),
  spread: () => ({
    title: 'Spread',
    body: 'The difference in price between the highest bid (the price a buyer is willing to buy for) and lowest ask (the price a seller is willing to sell for) an asset.',
  }),
  'step-size': () => ({
    title: 'title_step-size', //stringGetter({ key: TOOLTIP_STRING_KEYS.STEP_SIZE_TITLE }),
    body: 'body_step-size', //stringGetter({ key: TOOLTIP_STRING_KEYS.STEP_SIZE_BODY }),
  }),
  'taker-fee': () => ({
    title: 'title_taker-fee', //stringGetter({ key: TOOLTIP_STRING_KEYS.TAKER_FEE_TITLE }),
    body: 'body_taker-fee', //stringGetter({ key: TOOLTIP_STRING_KEYS.TAKER_FEE_BODY }),
  }),
  'tick-size': () => ({
    title: 'title_tick-size', //stringGetter({ key: TOOLTIP_STRING_KEYS.TICK_SIZE_TITLE }),
    body: 'body_tick-size', //stringGetter({ key: TOOLTIP_STRING_KEYS.TICK_SIZE_BODY }),
  }),
  'time-in-force': () => ({
    title: 'title_time-in-force', //stringGetter({ key: TOOLTIP_STRING_KEYS.TIME_IN_FORCE_TITLE }),
    body: 'body_time-in-force', //stringGetter({ key: TOOLTIP_STRING_KEYS.TIME_IN_FORCE_BODY }),
  }),
  'trailing-percent': () => ({
    title: 'title_trailing-percent', //stringGetter({ key: TOOLTIP_STRING_KEYS.TRAILING_PERCENT_TITLE }),
    body: 'body_trailing-percent', //stringGetter({ key: TOOLTIP_STRING_KEYS.TRAILING_PERCENT_BODY }),
  }),
  'trigger-price': () => ({
    title: 'Trigger Price', //stringGetter({ key: TOOLTIP_STRING_KEYS.TRIGGER_PRICE_TITLE }),
    body: 'When the oracle price or last trade price of this market crosses your trigger price, your stop/take profit order will either convert to a limit order or execute as a market order.', //stringGetter({ key: TOOLTIP_STRING_KEYS.TRIGGER_PRICE_BODY }),
  }),
  'unrealized-pnl': () => ({
    title: 'title_unrealized', //stringGetter({ key: TOOLTIP_STRING_KEYS.UNREALIZED_PNL_TITLE }),
    body: 'body_unrealized', //stringGetter({ key: TOOLTIP_STRING_KEYS.UNREALIZED_PNL_BODY }),
  }),
} as const
