import { ColumnDef } from '@tanstack/react-table'
import { Asset, AssetType } from '@/graphql/generated/graphql-request'
import { createNotification, NotificationType } from '@/utils/notificationUtils'
import { formatNumber } from '@/utils/numberUtils'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { CreateOrUpdateAsset } from '@/components/administration/CreateOrUpdateAsset'
import { Link } from 'react-router-dom'
import { PRICE_DECIMALS } from '@/utils/constants'
import { parseAndFormatDate } from '@/utils/dateUtils'

export const tableColumnsAssets: ColumnDef<Asset>[] = [
  {
    accessorKey: 'asset',
    header: () => <div className='text-xs text-left text-white'>Asset</div>,
    cell: ({ row }) => {
      const symbol = row.original.symbol
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(symbol)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Asset ${symbol} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {symbol}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'type',
    header: () => <div className='text-xs text-left text-white'>Type</div>,
    cell: ({ row }) => {
      const type = row.original.type
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(type)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Type ${type} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {type}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'description',
    header: () => <div className='text-xs text-left text-white'>Description</div>,
    cell: ({ row }) => {
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{row.original.description}</div>
    },
    size: 300,
  },
  {
    accessorKey: 'collateralWeight',
    header: () => <div className='text-xs text-left text-white'>Collateral Weight</div>,
    cell: ({ row }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>{row.original.collateralWeight}</div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'liquidationThreshold',
    header: () => <div className='text-xs text-left text-white'>Liquidation Threshold</div>,
    cell: ({ row }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>
          {row.original.liquidationThreshold}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'maxSupply',
    header: () => <div className='text-xs text-left text-white'>Max Supply</div>,
    cell: ({ row }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>
          {formatNumber(row.original.maxSupply, 0)}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'maxUtilization',
    header: () => <div className='text-xs text-left text-white'>Max Utilization</div>,
    cell: ({ row }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>{row.original.maxUtilization}</div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'bond',
    header: () => <div className='text-xs text-left text-white'>Bond</div>,
    cell: ({ row }) => {
      if (!row.original.bond || row.original.type !== AssetType.Bond) return null
      const bond = row.original.bond
      return (
        <div className={'flex flex-row justify-between'}>
          <div className='w-full flex flex-col justify-between gap-1 items'>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Name</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>{bond?.name}</div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Coupon Start Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.couponStartDate}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Final Coupon Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.finalCouponDate}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Issue Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.issueDate}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Issuer</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.issuer}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Maturity Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {parseAndFormatDate(bond?.maturityDate)}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Payments Per Year</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.numberOfPaymentsPerYear}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Yield</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>{bond?.yield}</div>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between gap-1 items'>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Coupon Frequency</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.couponFrequency}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Coupon Rate</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(bond?.coupon ?? 0, PRICE_DECIMALS)}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Asset Type</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.assetBondType}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>CUSIP</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>{bond?.cusip}</div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>ISIN</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>{bond?.isin}</div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Ticker</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.ticker}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Bond Details URL</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.bondDetailsUrl && (
                  <Link target={'_blank'} to={bond?.bondDetailsUrl ?? '/'}>
                    {bond?.bondDetailsUrl}{' '}
                  </Link>
                )}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Education URL</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.educationUrl && (
                  <Link target={'_blank'} to={bond?.educationUrl ?? '/'}>
                    {bond?.educationUrl}{' '}
                  </Link>
                )}
              </div>
            </div>
            <div className='w-full flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Price History URL</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.priceHistoryUrl && (
                  <Link target={'_blank'} to={bond?.priceHistoryUrl ?? '/'}>
                    {bond?.priceHistoryUrl}{' '}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    },
    size: 2000,
  },
  {
    accessorKey: 'updateAsset',
    header: () => <div className='text-xs text-left text-white'>Update Asset</div>,
    cell: ({ row }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className={'border-0 bg-card hover:bg-card'}>
            <button
              type='button'
              className='w-[150px] bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
            >
              Update Asset
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent side='left' className='w-full'>
            <DropdownMenuLabel>
              <div className={'w-full ps-8 text-base text-primary'}>Update Asset</div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <CreateOrUpdateAsset asset={row.original} />
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    size: 100,
  },
]
