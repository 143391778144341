import { getSdk } from '@/graphql/generated/graphql-request'
import { queryClient } from '@/services/api'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTransactionReceipt } from 'wagmi'

interface Deposit {
  chainID: number
  txHex: string
  fromHex: string
  toHex: string
  tokenContractHex: string
  amount: string
}

const useHandleDeposit = () => {
  const [deposit, setDeposit] = useState<Deposit>()
  const result = useTransactionReceipt({
    hash: deposit?.txHex as any,
    chainId: deposit?.chainID,
  })

  const depositMutation = useMutation({
    mutationFn: () =>
      getSdk(graphQLClient)
        .ExternalDeposit({
          deposit: {
            chainID: deposit?.chainID || 0,
            txHex: deposit?.txHex || '',
            fromHex: deposit?.fromHex || '',
            toHex: deposit?.toHex || '',
            tokenContractHex: deposit?.tokenContractHex || '',
          },
        })
        .then(() => {
          // params.selectedAsset, params.text, params.amount, params.isAdding
          createNotification(
            'Deposit',
            `You successfully deposited ${deposit?.amount} USDC on Dora. You can start trading now.`,
            NotificationType.SIMPLE,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on ExternalDeposit'
          createNotification('Error on ExternalDeposit', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['USER_BALANCES'] }).finally()
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const deposits: Deposit[] = JSON.parse(window.localStorage.getItem('external-deposit') || '[]')
      if (deposits.length) {
        setDeposit(deposits[0])
      }
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!!result.data?.status) {
      const deposits: Deposit[] = JSON.parse(window.localStorage.getItem('external-deposit') || '[]')
      const handlingDepositIndex = deposits.findIndex((d) => d.txHex === result.data?.transactionHash)

      if (result.data?.status === 'success') {
        depositMutation.mutate()
      } else if (result.data?.status === 'reverted') {
        createNotification(
          'Error on ExternalDeposit',
          'Transaction is reverted',
          NotificationType.SIMPLE,
          NotificationStatus.ERROR,
        )
      }
      window.localStorage.setItem(
        'external-deposit',
        JSON.stringify([...deposits.slice(0, handlingDepositIndex), ...deposits.slice(handlingDepositIndex + 1)]),
      )
    }
  }, [result.data?.status, result.data?.transactionHash])
}

export default useHandleDeposit
