import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const parseAndFormatDate = (dateString: string): string => {
  const formats = ['DD/MM/YYYY', 'DD-MM-YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD']
  const parsedDate = dayjs(dateString, formats, true)
  return parsedDate.isValid() ? parsedDate.format('DD/MM/YYYY') : 'Invalid Date'
}
