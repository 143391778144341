import { gql } from 'graphql-request'

export const POOLS_BALANCE_UPDATED = gql`
  subscription PoolBalancesUpdated {
    poolBalancesUpdated {
      uid
      type
      createdAt
      baseAsset {
        assetID
        amount
        sortAmount
      }
      quoteAsset {
        assetID
        amount
        sortAmount
      }
      poolShares {
        assetID
        amount
        sortAmount
      }
      yieldSpaceProp {
        g
        maturityAt
      }
      prodProp {
        swapFee
      }
    }
  }
`
