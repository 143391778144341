import BondHeader from '@/components/BondHeader'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useAsset } from '@/hooks/useAsset'
import { AssetBond } from '@/graphql/generated/graphql-request'
import { ErrorBoundary } from 'react-error-boundary'
import { TvChart } from '@/components/charts/TvChart'
import { BondDetailsDescription } from '@/components/bondDetails/BondDetailsDescription'
import { BondDetailsInfo } from '@/components/bondDetails/BondDetailsInfo'
import { useOrderBooks } from '@/hooks/useOrderBooks'

export const Bonds = () => {
  const { orderBookId } = useParams()
  const { orderBooks } = useOrderBooks()
  const isin = orderBookId?.split('-')[0]
  const { asset } = useAsset(isin ?? '')
  const bond = asset?.bond as AssetBond | undefined

  const orderBook = useMemo(
    () => orderBooks.find((orderBook) => orderBook.orderBookID === orderBookId),
    [orderBooks, orderBookId],
  )

  if (!asset) return <div> Invalid Bond</div>

  return (
    <div className='p-2 h-full '>
      <div className='mb-2'>{bond && <BondHeader bond={bond} />}</div>

      <div className='py-4 grid grid-cols-1 lg:grid-cols-[500px_auto] w-full gap-2 h-full'>
        <div className={'h-fit flex flex-col col-start-1 pt-4 panel'}>
          <BondDetailsDescription baseAsset={asset} orderBookId={orderBookId} top={true} />
          <BondDetailsInfo baseAsset={asset} orderBook={orderBook} top={true} />
        </div>

        <div className='lg:col-start-2 h-auto lg:mb-32 min-h-[350px]'>
          <ErrorBoundary fallback={<p>Something went wrong with Chart</p>}>
            <TvChart />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}
