import { Dispatch, SetStateAction, useMemo } from 'react'
import { useOrderBookID } from '@/hooks/useOrderBookID'
import { useAllAssets } from '@/hooks/useAsset'
import { useOrderBooks } from '@/hooks/useOrderBooks'
import { BondDetailsDescription } from '@/components/bondDetails/BondDetailsDescription'
import { BondDetailsInfo } from '@/components/bondDetails/BondDetailsInfo'

interface BondDetailsPanelProps {
  setShowBondDetails: Dispatch<SetStateAction<boolean>>
}

export const BondDetailsPanel = ({ setShowBondDetails }: BondDetailsPanelProps) => {
  const { orderBookId } = useOrderBookID()
  const { orderBooks } = useOrderBooks()
  const { allAssets } = useAllAssets()

  const baseAsset = useMemo(
    () => allAssets.find((asset) => asset.uid === orderBookId.split('-')[0]),
    [allAssets, orderBookId],
  )

  const orderBook = useMemo(
    () => orderBooks.find((orderBook) => orderBook.orderBookID === orderBookId),
    [orderBooks, orderBookId],
  )

  if (!orderBookId || !baseAsset) {
    return <div>No pair selected</div>
  }

  return (
    <div className={'w-full h-full panel z-20'}>
      <button
        className='absolute font-bold top-2.5 right-3.5 bg-transparent text-white border-none text-2xl cursor-pointer'
        onClick={() => setShowBondDetails(false)}
      >
        &#x2715;
      </button>

      <div className={'w-full h-full grid grid-cols-4 grid-rows-1 items-center gap-8'}>
        <div className={'w-full h-full text-start mt-0 col-start-1 text-xs text-white'}>Bond Details</div>
        <BondDetailsDescription baseAsset={baseAsset} orderBookId={orderBookId} />

        <BondDetailsInfo baseAsset={baseAsset} orderBook={orderBook} />
      </div>
    </div>
  )
}
