import { GraphQLClient, RequestOptions } from 'graphql-request'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders']
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Any: { input: any; output: any }
}

export type AccrueAllInterestResult = {
  __typename?: 'AccrueAllInterestResult'
  /** Counts the number of accounts whose AccrueInterest transactions were not executed */
  accountsDelayed: Scalars['Int']['output']
}

export type AddFundsToUserInput = {
  poolUID: Scalars['String']['input']
}

export type AddLiquidityPoolInput = {
  assets: Array<BalanceInput>
  poolUID: Scalars['String']['input']
}

export enum AdvancedOrderType {
  /** Every order besides stop and take profit */
  Normal = 'NORMAL',
  /** Stop loss order that is triggered when trigger price conditions are met on the orderbook */
  Stop = 'STOP',
  /** Take profit order that is triggered when trigger price conditions are met on the orderbook */
  TakeProfit = 'TAKE_PROFIT',
}

/**
 * Amount Input is a generic input type for mutations which only require a single number input.
 * Since the amount field is a string, it can be parsed as either an int or a float by the mutation.
 */
export type AmountInput = {
  amount: Scalars['String']['input']
}

export type Asset = {
  __typename?: 'Asset'
  bond?: Maybe<AssetBond>
  collateralWeight: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  decimals: Scalars['Int']['output']
  description: Scalars['String']['output']
  liquidationThreshold: Scalars['String']['output']
  maxSupply: Scalars['String']['output']
  maxUtilization: Scalars['String']['output']
  symbol: Scalars['String']['output']
  type: AssetType
  uid: Scalars['String']['output']
  usage: Array<AssetUsage>
}

export type AssetBond = {
  __typename?: 'AssetBond'
  assetBondType?: Maybe<AssetBondType>
  bondDetailsUrl?: Maybe<Scalars['String']['output']>
  coupon?: Maybe<Scalars['Float']['output']>
  couponFrequency?: Maybe<CouponFrequency>
  couponStartDate: Scalars['String']['output']
  cusip?: Maybe<Scalars['String']['output']>
  educationUrl?: Maybe<Scalars['String']['output']>
  finalCouponDate: Scalars['String']['output']
  isin: Scalars['String']['output']
  issueDate: Scalars['String']['output']
  issuer: Scalars['String']['output']
  maturityDate: Scalars['String']['output']
  name: Scalars['String']['output']
  numberOfPaymentsPerYear: Scalars['Int']['output']
  priceHistoryUrl?: Maybe<Scalars['String']['output']>
  ticker?: Maybe<Scalars['String']['output']>
  /** Yearly yield */
  yield: Scalars['Float']['output']
}

export enum AssetBondType {
  CorporateBond = 'CorporateBond',
  StableCoin = 'StableCoin',
  TreasuryBond = 'TreasuryBond',
  Usd = 'USD',
}

export enum AssetType {
  Bond = 'Bond',
  Currency = 'Currency',
  PoolShare = 'PoolShare',
}

export enum AssetUsage {
  /** DirectBorrow enables an asset for Supply, Withdraw, and Repay as well as direct borrowing via leverage. */
  DirectBorrow = 'DirectBorrow',
  /**
   * Onboard allows transfer to and from external banks.
   * It also enables an asset for ClaimInterest and PayInterest.
   *
   * Onboard is exclusive: it should forbid Trade and Borrow usage on the same asset.
   * Possible exception: for swaps between onboarded dollar assets and a designated yieldbearing stablecoin.
   */
  Onboard = 'Onboard',
  /** PoolShare enables an asset for RemoveLiquidityFromPool */
  PoolShare = 'PoolShare',
  /** Trade enables an asset for AddLiquidityToPool and CreateOrder. */
  Trade = 'Trade',
  /** VirtualBorrow enables an asset for indirect borrowing, where assets are minted and backed by stable collateral. */
  VirtualBorrow = 'VirtualBorrow',
}

/**
 * A Balance represents an integer amount of an asset, and can be used to represent funds held by a user,
 * leverage positions, pool balances, orders, or other data.
 */
export type Balance = {
  __typename?: 'Balance'
  /**
   * Amount is a non-negative integer quantity of an asset.
   * This is the base amount with regards to the asset's exponent.
   * For example, 1.5 of an asset with exponent 3 will by represented as amount "1500".
   */
  amount: Scalars['String']['output']
  /** Asset ID specifies the bond (or stablecoin) held in this balance. */
  assetID: Scalars['String']['output']
  /**
   * SortAmount is a copy of Amount represented as a float. It may have been rounded a bit, so the original Amount
   * should still be considered as the source of truth. However, sorting and aggregation will work with this field.
   */
  sortAmount: Scalars['Float']['output']
}

export type BalanceInput = {
  amount: Scalars['String']['input']
  assetID: Scalars['String']['input']
}

export type BalanceToUserInput = {
  balance: BalanceInput
  userUID: Scalars['String']['input']
}

export type BondInput = {
  assetBondType?: InputMaybe<AssetBondType>
  bondDetailsUrl?: InputMaybe<Scalars['String']['input']>
  coupon?: InputMaybe<Scalars['Float']['input']>
  couponFrequency?: InputMaybe<CouponFrequency>
  couponStartDate: Scalars['String']['input']
  cusip?: InputMaybe<Scalars['String']['input']>
  educationUrl?: InputMaybe<Scalars['String']['input']>
  finalCouponDate: Scalars['String']['input']
  isin: Scalars['String']['input']
  issueDate: Scalars['String']['input']
  issuer: Scalars['String']['input']
  maturityDate: Scalars['String']['input']
  name: Scalars['String']['input']
  numberOfPaymentsPerYear: Scalars['Int']['input']
  priceHistoryUrl?: InputMaybe<Scalars['String']['input']>
  ticker?: InputMaybe<Scalars['String']['input']>
  yield: Scalars['Float']['input']
}

export enum BotStrategy {
  Dynamic = 'Dynamic',
  Static = 'Static',
}

export type CancelOrderInput = {
  /** The orderbook in which to cancel a user's orders. */
  orderBookID: Scalars['String']['input']
  /** The ID of the order to cancel. Use 'ALL' to cancel all of the user's orders in one orderbook. */
  orderID: Scalars['String']['input']
}

export type Candle = {
  __typename?: 'Candle'
  cleanClose?: Maybe<Scalars['Float']['output']>
  cleanHigh?: Maybe<Scalars['Float']['output']>
  cleanLow?: Maybe<Scalars['Float']['output']>
  cleanOpen?: Maybe<Scalars['Float']['output']>
  close: Scalars['Float']['output']
  high: Scalars['Float']['output']
  low: Scalars['Float']['output']
  open: Scalars['Float']['output']
  /** Time is unix time in seconds truncated to the minute. */
  time: Scalars['Int']['output']
  uid: Scalars['String']['output']
  volume: Scalars['Int']['output']
}

export type CandleData = {
  __typename?: 'CandleData'
  assetOutUID: Scalars['String']['output']
  close: Scalars['Float']['output']
  high: Scalars['Float']['output']
  low: Scalars['Float']['output']
  open: Scalars['Float']['output']
  source: CandleSource
  volume: Scalars['Int']['output']
}

export enum CandleSource {
  BondMarket = 'BondMarket',
}

export type ChartData = {
  __typename?: 'ChartData'
  assetInUID: Scalars['String']['output']
  candle: CandleData
  point: PointData
  /** Time is unix time in secconds truncated to the minute. */
  time: Scalars['Int']['output']
  /** Type [price or yield] fill point, [candle] fill candle.  */
  type: ChartType
  uid: Scalars['String']['output']
}

export enum ChartType {
  Candle = 'Candle',
  Price = 'Price',
  Yield = 'Yield',
}

export type Coin = {
  __typename?: 'Coin'
  amount: Scalars['String']['output']
  denom: Scalars['String']['output']
}

export enum CouponFrequency {
  Annual = 'Annual',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  SemiAnnual = 'SemiAnnual',
  Zero = 'Zero',
}

export type CreateAmmInput = {
  g: Scalars['Float']['input']
  poolMaturity: Scalars['Int']['input']
}

export type CreateAdvancedOrderInput = {
  advancedType: AdvancedOrderType
  orderInput: CreateOrderWithPriceInput
  triggerPrice: Scalars['Float']['input']
}

export type CreateAssetInput = {
  bond?: InputMaybe<BondInput>
  collateralWeight: Scalars['String']['input']
  decimals: Scalars['Int']['input']
  description: Scalars['String']['input']
  liquidationThreshold: Scalars['String']['input']
  maxSupply: Scalars['String']['input']
  maxUtilization: Scalars['String']['input']
  symbol: Scalars['String']['input']
  type: AssetType
  usage: Array<AssetUsage>
}

export type CreateOrderBookInput = {
  baseUID: Scalars['String']['input']
  priceDisplayMultiplier: Scalars['Float']['input']
  quoteUID: Scalars['String']['input']
  tickSize: Scalars['Float']['input']
}

export type CreateOrderInput = {
  amountIn: Scalars['String']['input']
  amountOut: Scalars['String']['input']
  assetInID: Scalars['String']['input']
  assetOutID: Scalars['String']['input']
  inverseLeverage?: InputMaybe<Scalars['Float']['input']>
  orderType: OrderType
}

export type CreateOrderWithPriceInput = {
  amountIn?: InputMaybe<Scalars['String']['input']>
  amountOut?: InputMaybe<Scalars['String']['input']>
  assetInID: Scalars['String']['input']
  assetOutID: Scalars['String']['input']
  inverseLeverage?: InputMaybe<Scalars['Float']['input']>
  orderType: OrderType
  price: Scalars['Float']['input']
}

export type CreatePoolInput = {
  ammProp?: InputMaybe<CreateAmmInput>
  baseAsset: BalanceInput
  prodProp?: InputMaybe<CreateProductInput>
  quoteAsset: BalanceInput
}

export type CreateProductInput = {
  swapFee: Scalars['Float']['input']
}

export type DeleteCandleDataInput = {
  assetInID: Scalars['String']['input']
  assetOutID: Scalars['String']['input']
  to: Scalars['Int']['input']
}

export type DeltaLevel = {
  __typename?: 'DeltaLevel'
  delta: Scalars['Float']['output']
  level: Scalars['Int']['output']
}

export type DeltaLevelInput = {
  delta: Scalars['Float']['input']
  level: Scalars['Int']['input']
}

export type DeltaMult = {
  __typename?: 'DeltaMult'
  delta: Scalars['Float']['output']
  mult: Scalars['Int']['output']
}

export type DeltaMultInput = {
  delta: Scalars['Float']['input']
  mult: Scalars['Int']['input']
}

export type ExternalDepositInput = {
  chainID: Scalars['Int']['input']
  fromHex: Scalars['String']['input']
  toHex: Scalars['String']['input']
  tokenContractHex: Scalars['String']['input']
  txHex: Scalars['String']['input']
}

export type ExternalWithdrawInput = {
  amount: Scalars['String']['input']
  chainID: Scalars['Int']['input']
  denom: Scalars['String']['input']
  toHex: Scalars['String']['input']
}

export type FloatPrice = {
  __typename?: 'FloatPrice'
  assetID: Scalars['String']['output']
  price: Scalars['Float']['output']
}

export enum InputAsset {
  Bond = 'Bond',
  Stable = 'Stable',
}

/**
 * Interest tracks an individual user's interest earnings and payments.
 * It is also used in the module to track the sum of all users.
 */
export type Interest = {
  __typename?: 'Interest'
  /** Claimed interest increases when a user claims their interest, and never decreases. */
  claimed: Scalars['String']['output']
  /** Earned interest has been earned but not yet claimed. It decreases when a user claims their interest. */
  earned: Scalars['String']['output']
  /** LastUpdated is the unix time a user's interest was last updated. Not used for module interest. */
  lastUpdated: Scalars['Int']['output']
  /** Owed interest has accrued on a user's borrows but has not yet been paid off. It decreases when the user pays. */
  owed: Scalars['String']['output']
  /** Paid interest increases when the user pays, and never decreases. */
  paid: Scalars['String']['output']
}

export type LeveragePosition = {
  __typename?: 'LeveragePosition'
  borrowed: Array<Balance>
  supplied: Array<Balance>
}

export type LeverageValue = {
  __typename?: 'LeverageValue'
  /** The maximum borrowed value a user is allowed to reach via leverage. */
  borrowLimit: Scalars['Float']['output']
  /** The total dollar value of a user's borrowed bonds and stablecoins. */
  borrowed: Scalars['Float']['output']
  /** The total dollar value of a user's assets, excluding LP shares and supplied stablecoins. */
  collateral: Scalars['Float']['output']
  /** The maximum borrowed value a user is allowed to reach before liquidation. */
  liquidationThreshold: Scalars['Float']['output']
  /** The total dollar value of a user's supplied stablecoins. */
  supplied: Scalars['Float']['output']
}

export type LiquidateInput = {
  userUID: Scalars['String']['input']
}

export type MaxBorrowInput = {
  assetIn: Scalars['String']['input']
  maxSlippage: Scalars['Float']['input']
  orderbookID: Scalars['String']['input']
}

export type MaxBorrowResult = {
  __typename?: 'MaxBorrowResult'
  /** Asset ID being spent and borrowed */
  assetIn: Scalars['String']['output']
  /** Asset ID being purchased */
  assetOut: Scalars['String']['output']
  /** Maximum amount of AssetIn able to spend from balance. Never greater than user's balance. */
  maxAmountIn: Scalars['Int']['output']
  /** Maximum amount of AssetIn which can be borrowed IF user spends maxAmountIn from balance */
  maxBorrowIfMaxAmountIn: Scalars['Int']['output']
  /** Maximum amount of AssetIn which can be borrowed IF user spends minAmountIn from balance */
  maxBorrowIfMinAmountIn: Scalars['Int']['output']
  /** Max slippage allowed in calculation */
  maxSlippage: Scalars['Float']['output']
  /** Minimum amount of AssetIn to spend from balance. Generally zero. */
  minAmountIn: Scalars['Int']['output']
  /** Price used in calculation */
  price: Scalars['Float']['output']
}

export type Module = {
  __typename?: 'Module'
  /**
   * balance tracks how many stablecoins have been supplied to the module by users, EXCLUDING stablecoins which have been borrowed out.
   * This is the actual amount of stablecoins held in the module, excluding interestHeld.
   * The sum amount of all balance amounts (across all asset IDs) should always be greater than the sum of all virtual bond amounts.
   */
  balance: Array<Balance>
  /**
   * borrowed tracks how many stablecoins have been borrowed directly by users (not minted as virtual assets)
   * Its amount for each stablecoin is the sum of that specific stablecoin borrowed by all users.
   * The borrowed amount of each asset cannot exceed the asset's supplied amount.
   */
  borrowed: Array<Balance>
  /** dollarAssetsUID contains the list of all assets which are considered actual dollars, and whose price is always $1 */
  dollarAssetsUID: Array<Scalars['String']['output']>
  /**
   * interestHeld contains assets paid to the module as interest by borrowers, but not yet claimed by suppliers.
   * It may contain multiple asset types if multiple assets are supported for interest payments.
   */
  interestHeld: Array<Balance>
  /**
   * supplied tracks how many stablecoins have been supplied to the module by users, INCLUDING those which are borrowed out.
   * Its amount for each asset should equal the sum of the asset supplied by all users.
   * The supplied amount of each asset cannot exceed the asset's collateral amount.
   */
  supplied: Array<Balance>
  /**
   * virtualBonds tracks how many virtual bonds have been minted by the module and borrowed out by users, but not yet repaid and burned.
   * It should not contain stablecoin assets, and its amount for each asset should equal the sum of borrows across all users.
   */
  virtualBonds: Array<Balance>
}

export type ModulePositions = {
  __typename?: 'ModulePositions'
  balance: Array<Balance>
  borrowed: Array<Balance>
  supplied: Array<Balance>
  virtual: Array<Balance>
}

export type Mutation = {
  __typename?: 'Mutation'
  AddBalanceToUser?: Maybe<Balance>
  AddBalances?: Maybe<Balance>
  AddFundsToUser: Scalars['Boolean']['output']
  AddLiquidityToPool?: Maybe<PoolBalance>
  CancelOrder: Scalars['Boolean']['output']
  CancelUserOrders: Scalars['Boolean']['output']
  ClaimInterest: Transaction
  CreateAdvancedOrder?: Maybe<Order>
  /** SuperAdmin mutations */
  CreateAsset?: Maybe<Asset>
  /** Bond Trader Mutations */
  CreateOrder?: Maybe<Order>
  CreateOrderWithPrice?: Maybe<Order>
  CreateOrders: Scalars['Boolean']['output']
  CreatePool?: Maybe<PoolAsset>
  CreateWallet: Scalars['String']['output']
  DeleteCandleData: Scalars['Int']['output']
  DeleteMe: Scalars['Boolean']['output']
  /** Wallet Mutations */
  ExternalDeposit?: Maybe<Balance>
  ExternalWithdraw: Scalars['String']['output']
  Liquidate: Transaction
  PayInterest: Transaction
  RemoveBalanceFromUser?: Maybe<Balance>
  RemoveBalances?: Maybe<Balance>
  RemoveLiquidityFromPool?: Maybe<RemoveLiquidityFromPoolResult>
  Repay: Transaction
  /** SetMatchmakingInterval changes how frequently matchmaking is called. Range 0-60 seconds (0 is disabled). */
  SetMatchmakingInterval: Scalars['Boolean']['output']
  StartOrderBot: Scalars['Boolean']['output']
  StartTraderBot: Scalars['Boolean']['output']
  StopOrderBot: Scalars['Boolean']['output']
  StopTraderBot: Scalars['Boolean']['output']
  Supply: Transaction
  UpdateAsset?: Maybe<Asset>
  /** User Mutations */
  UpdateUser?: Maybe<User>
  UpdateUsersRoles?: Maybe<User>
  /** Bots management - admins only */
  UpsertOrderBot: Scalars['Boolean']['output']
  UpsertTraderBot: Scalars['Boolean']['output']
  Withdraw: Transaction
}

export type MutationAddBalanceToUserArgs = {
  balanceToUser?: InputMaybe<BalanceToUserInput>
}

export type MutationAddBalancesArgs = {
  balanceToAdd?: InputMaybe<BalanceInput>
}

export type MutationAddFundsToUserArgs = {
  addFunds?: InputMaybe<AddFundsToUserInput>
}

export type MutationAddLiquidityToPoolArgs = {
  addLiquidity?: InputMaybe<AddLiquidityPoolInput>
}

export type MutationCancelOrderArgs = {
  input: CancelOrderInput
}

export type MutationCancelUserOrdersArgs = {
  orderBookID: Scalars['String']['input']
}

export type MutationCreateAdvancedOrderArgs = {
  input?: InputMaybe<CreateAdvancedOrderInput>
}

export type MutationCreateAssetArgs = {
  newAsset?: InputMaybe<CreateAssetInput>
}

export type MutationCreateOrderArgs = {
  newOrder?: InputMaybe<CreateOrderInput>
}

export type MutationCreateOrderWithPriceArgs = {
  newOrder?: InputMaybe<CreateOrderWithPriceInput>
}

export type MutationCreateOrdersArgs = {
  newOrders?: InputMaybe<Array<InputMaybe<CreateOrderInput>>>
}

export type MutationCreatePoolArgs = {
  input?: InputMaybe<CreatePoolInput>
}

export type MutationDeleteCandleDataArgs = {
  input?: InputMaybe<DeleteCandleDataInput>
}

export type MutationExternalDepositArgs = {
  deposit: ExternalDepositInput
}

export type MutationExternalWithdrawArgs = {
  input?: InputMaybe<ExternalWithdrawInput>
}

export type MutationLiquidateArgs = {
  liquidateInput: LiquidateInput
}

export type MutationPayInterestArgs = {
  payInput: AmountInput
}

export type MutationRemoveBalanceFromUserArgs = {
  balanceToUser?: InputMaybe<BalanceToUserInput>
}

export type MutationRemoveBalancesArgs = {
  balanceToRmv?: InputMaybe<BalanceInput>
}

export type MutationRemoveLiquidityFromPoolArgs = {
  removeLiquidity?: InputMaybe<RemoveLiquidityFromPoolInput>
}

export type MutationRepayArgs = {
  asset: BalanceInput
}

export type MutationSetMatchmakingIntervalArgs = {
  interval: Scalars['Int']['input']
}

export type MutationStartOrderBotArgs = {
  botID: Scalars['String']['input']
}

export type MutationStartTraderBotArgs = {
  botID: Scalars['String']['input']
}

export type MutationStopOrderBotArgs = {
  botID: Scalars['String']['input']
}

export type MutationStopTraderBotArgs = {
  botID: Scalars['String']['input']
}

export type MutationSupplyArgs = {
  asset: BalanceInput
}

export type MutationUpdateAssetArgs = {
  updateAsset?: InputMaybe<UpdateAssetInput>
}

export type MutationUpdateUserArgs = {
  updatedUser?: InputMaybe<UpdateUserInput>
}

export type MutationUpdateUsersRolesArgs = {
  updatedRoles?: InputMaybe<UpdateUserRolesInput>
}

export type MutationUpsertOrderBotArgs = {
  bot: UpsertOrderBot
}

export type MutationUpsertTraderBotArgs = {
  bot: UpsertTraderBot
}

export type MutationWithdrawArgs = {
  asset: BalanceInput
}

export type Order = {
  __typename?: 'Order'
  /** AdvancedType for advanced orders */
  advancedType: AdvancedOrderType
  /**
   * AmountIn specifies the exact amount in an order needs to be filled, if the order is a sell.
   * For limit buys, this is the maximum amount in. For market buys, it is zero.
   */
  amountIn: Scalars['String']['output']
  /**
   * AmountOut specifies the exact amount out an order needs to be filled, if the order is a buy.
   * For limit sells, this is the minimum amount out. For market sells, it is zero.
   */
  amountOut: Scalars['String']['output']
  assetInID: Scalars['String']['output']
  assetOutID: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  /** InFilled is the exact amount of assetInID which has been spent by this order. */
  inFilled: Scalars['String']['output']
  /**
   * InverseLeverage is the portion of balanceIn which should be borrowed instead of coming out of the user's balance.
   * It ranges from 0 to 1: at 0, no leverage is used. At 1, no balance is used.
   * To achieve a given leverage multilier (e.g. 8x), this should be set to the inverse (e.g. 0.125, or 1/8).
   * If this field is empty, it should be treated as 1.
   */
  inverseLeverage?: Maybe<Scalars['Float']['output']>
  /** Modifiers are tags indicating special behaviors for an order, such as fill-or-kill or liquidation order */
  modifiers: Array<OrderModifier>
  orderBookID: Scalars['String']['output']
  orderID: Scalars['String']['output']
  /** OutFilled is the exact amount of assetOutID which has been bought by this order. */
  outFilled: Scalars['String']['output']
  /**
   * Price refers to the limit price of any limit order, or the executed price of any partial or completed market order.
   * It will be initialized at market price (estimated) for any market orders which have not executed.
   * Prices are always defined as the ratio of amounts assetQuote/assetBase.
   * This interpretation is independent of which asset is assetInID for this particular transaction.
   * Price will be a multiple of tickSize of the orderbook in which this order is found.
   */
  price: Scalars['Float']['output']
  /**
   * SortAmountIn is a copy of AmountIn represented as a float. It may have been rounded a bit, so the original AmountIn
   * should still be considered as the source of truth. However, sorting and aggregation will work with this field.
   */
  sortAmountIn: Scalars['Float']['output']
  /**
   * SortAmountOut is a copy of AmountOut represented as a float. It may have been rounded a bit, so the original AmountOut
   * should still be considered as the source of truth. However, sorting and aggregation will work with this field.
   */
  sortAmountOut: Scalars['Float']['output']
  /** SortInFilled is a float copy of the inFilled field, possibly rounded but usable for aggregation */
  sortInFilled: Scalars['Float']['output']
  /** SortOutFilled is a float copy of the outFilled field, possibly rounded but usable for aggregation */
  sortOutFilled: Scalars['Float']['output']
  /** SortRemainingAmountIn is the remaining amountIn saved as float for aggregation queries */
  sortRemainingAmountIn: Scalars['Float']['output']
  /** state describes where the order is in its life cycle */
  state: OrderState
  /** TriggerPrice for advanced orders */
  triggerPrice: Scalars['Float']['output']
  /** type defines the behavior of the order */
  type: OrderType
  updatedAt: Scalars['Int']['output']
  userUID: Scalars['String']['output']
}

export type OrderBook = {
  __typename?: 'OrderBook'
  baseUID: Scalars['String']['output']
  orderBookID: Scalars['String']['output']
  orders: Array<SimpleOrder>
  priceDisplayMultiplier: Scalars['Float']['output']
  quoteUID: Scalars['String']['output']
  tickSize: Scalars['Float']['output']
}

export type OrderBot = {
  __typename?: 'OrderBot'
  amountList: Array<Scalars['Int']['output']>
  baseDecimals: Scalars['Int']['output']
  botID: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  customTick: Scalars['Float']['output']
  frequency: Scalars['Int']['output']
  levels: Scalars['Int']['output']
  maxMult: Scalars['Float']['output']
  minMult: Scalars['Float']['output']
  name: Scalars['String']['output']
  orderBookID: Scalars['String']['output']
  priceList: Array<Scalars['Float']['output']>
  quoteDecimals: Scalars['Int']['output']
  spread: Scalars['Float']['output']
  startingBaseAmount: Scalars['Float']['output']
  strategy: BotStrategy
  updatedAt: Scalars['Int']['output']
  updatedBy: Scalars['String']['output']
}

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** OrderCancel represents a user's pending request to cancel an order. */
export type OrderCancel = {
  __typename?: 'OrderCancel'
  orderBookID: Scalars['String']['output']
  orderID: Scalars['String']['output']
  userUID: Scalars['String']['output']
}

/** OrderModifier tags that can change Order behavior on creation or execution */
export enum OrderModifier {
  /** Liquidation orders are system-generated and cannot be cancelled by users. */
  Liquidation = 'LIQUIDATION',
}

export enum OrderState {
  /** Canceled orders are no longer open and were not executed, even partially. */
  Canceled = 'CANCELED',
  /** Created orders are open and have zero filledIn and filledOut amounts. */
  Created = 'CREATED',
  /** Executed orders are no longer open, and have either been filled to completion or canceled after partial. */
  Executed = 'EXECUTED',
  /** Partial orders are open and have nonzero filledIn and filledOut amounts. */
  Partial = 'PARTIAL',
}

export enum OrderType {
  LimitBuy = 'LIMIT_BUY',
  LimitSell = 'LIMIT_SELL',
  MarketBuy = 'MARKET_BUY',
  MarketSell = 'MARKET_SELL',
}

export type OrdersSize = {
  __typename?: 'OrdersSize'
  price: Scalars['Float']['output']
  size: Scalars['Float']['output']
}

export type OrdersSizeResponse = {
  __typename?: 'OrdersSizeResponse'
  buys: Array<OrdersSize>
  orderBookID: Scalars['String']['output']
  sells: Array<OrdersSize>
}

/** Pagination defines the structure for pagination adapter */
export type Pagination = {
  after?: InputMaybe<Scalars['Any']['input']>
  key?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<OrderBy>
}

export type PointData = {
  __typename?: 'PointData'
  value: Scalars['Float']['output']
}

export type Pool = {
  __typename?: 'Pool'
  baseAsset: Balance
  createdAt: Scalars['Int']['output']
  poolShares: Balance
  prodProp?: Maybe<ProductProperties>
  quoteAsset: Balance
  type: PoolType
  uid: Scalars['String']['output']
  yieldSpaceProp?: Maybe<YieldSpaceProp>
}

export type PoolAsset = {
  __typename?: 'PoolAsset'
  assetLP: Asset
  pool: Pool
}

export type PoolBalance = {
  __typename?: 'PoolBalance'
  newLiquidityShare: Scalars['String']['output']
  pool: Pool
}

export type PoolOrdersSummary = {
  __typename?: 'PoolOrdersSummary'
  midPrice: Scalars['Float']['output']
  orderBookID: Scalars['String']['output']
  spread: Scalars['Float']['output']
}

export enum PoolType {
  ConstantProduct = 'ConstantProduct',
  YieldSpace = 'YieldSpace',
}

export type ProductProperties = {
  __typename?: 'ProductProperties'
  swapFee: Scalars['Float']['output']
}

export type Query = {
  __typename?: 'Query'
  /**
   * DevQuery should be used for arbitrary debugging against the backend.
   * Input and output are kept as strings so the schema does not need to be changed.
   * Authentication is omitted for reliability's sake.
   */
  devQuery: Scalars['String']['output']
  estimateGas: Scalars['Float']['output']
  exploreTxs?: Maybe<Array<Maybe<Transaction>>>
  /** Wallet withdraws and deposits query - admin only */
  getAdminUserDeposits?: Maybe<Array<Maybe<WalletTx>>>
  getAdminUserWithdraws?: Maybe<Array<Maybe<WalletTx>>>
  getAllAssets: Array<Asset>
  getAllOrderBooks: Array<OrderBook>
  getAllPools: Array<Pool>
  getAllUserPositions: UserPositions
  getAllUsers: Array<User>
  getAppEnv: Scalars['String']['output']
  getAsset: Asset
  getCandles: Array<Candle>
  getDeposites?: Maybe<Array<Maybe<WalletTx>>>
  getOrders: Array<Order>
  getPoolOrdersSummary: PoolOrdersSummary
  getTrades: Array<Trade>
  getTxs?: Maybe<Array<Maybe<Transaction>>>
  getUser?: Maybe<User>
  getUserCustomToken: Scalars['String']['output']
  getWithdraws?: Maybe<Array<Maybe<WalletTx>>>
  initUser?: Maybe<User>
  lastTradePrice: Scalars['Float']['output']
  liquidationTargets: Array<Scalars['String']['output']>
  maxBorrow: MaxBorrowResult
  moduleInterest?: Maybe<Interest>
  moduleInterestHeld: Array<Balance>
  modulePositions?: Maybe<ModulePositions>
  orderBookOpenOrders: OrdersSizeResponse
  /** Bots management - admins only */
  orderBots: Array<OrderBot>
  simulateOrder: SwapResult
  traderBots: Array<TraderBot>
  userBalances?: Maybe<UserBalances>
  userInterest?: Maybe<Interest>
  userLeveragePositions?: Maybe<LeveragePosition>
  userLeverageValue: LeverageValue
  userOrderBookOrders: Array<Order>
}

export type QueryDevQueryArgs = {
  input: Scalars['String']['input']
}

export type QueryEstimateGasArgs = {
  estimateGasInput?: InputMaybe<ExternalWithdrawInput>
}

export type QueryExploreTxsArgs = {
  assetsUID?: InputMaybe<Array<Scalars['String']['input']>>
  from?: InputMaybe<Scalars['Int']['input']>
  orderBookID?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<Pagination>
  to?: InputMaybe<Scalars['Int']['input']>
  txID?: InputMaybe<Scalars['String']['input']>
  txTypes?: InputMaybe<Array<TransactionType>>
  userUID?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetAdminUserDepositsArgs = {
  pagination?: InputMaybe<Pagination>
  userID: Scalars['String']['input']
}

export type QueryGetAdminUserWithdrawsArgs = {
  pagination?: InputMaybe<Pagination>
  userID: Scalars['String']['input']
}

export type QueryGetAllAssetsArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryGetAllPoolsArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryGetAllUserPositionsArgs = {
  userUID: Scalars['String']['input']
}

export type QueryGetAssetArgs = {
  assetID: Scalars['String']['input']
}

export type QueryGetCandlesArgs = {
  assetInUID: Scalars['String']['input']
  assetOutUID: Scalars['String']['input']
  from: Scalars['Int']['input']
  resolution: Scalars['String']['input']
  to: Scalars['Int']['input']
}

export type QueryGetDepositesArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryGetOrdersArgs = {
  orderBookID: Scalars['String']['input']
  orderState?: InputMaybe<OrderState>
  orderType?: InputMaybe<OrderType>
  pagination?: InputMaybe<Pagination>
}

export type QueryGetPoolOrdersSummaryArgs = {
  orderBookID: Scalars['String']['input']
}

export type QueryGetTradesArgs = {
  all: Scalars['Boolean']['input']
  assetInUID: Scalars['String']['input']
  assetOutUID: Scalars['String']['input']
  pagination?: InputMaybe<Pagination>
}

export type QueryGetTxsArgs = {
  ids: Array<Scalars['String']['input']>
  pagination?: InputMaybe<Pagination>
}

export type QueryGetUserArgs = {
  userUID: Scalars['String']['input']
}

export type QueryGetUserCustomTokenArgs = {
  userUID: Scalars['String']['input']
}

export type QueryGetWithdrawsArgs = {
  pagination?: InputMaybe<Pagination>
}

export type QueryLastTradePriceArgs = {
  assetInUID: Scalars['String']['input']
  assetOutUID: Scalars['String']['input']
}

export type QueryMaxBorrowArgs = {
  input: MaxBorrowInput
}

export type QueryOrderBookOpenOrdersArgs = {
  orderBookID: Scalars['String']['input']
}

export type QueryOrderBotsArgs = {
  botID?: InputMaybe<Scalars['String']['input']>
}

export type QuerySimulateOrderArgs = {
  swapInput?: InputMaybe<SwapInput>
}

export type QueryTraderBotsArgs = {
  botID?: InputMaybe<Scalars['String']['input']>
}

export type QueryUserLeverageValueArgs = {
  userUID: Scalars['String']['input']
}

export type QueryUserOrderBookOrdersArgs = {
  orderBookID: Scalars['String']['input']
}

export type RemoveLiquidityFromPoolInput = {
  poolUID: Scalars['String']['input']
  shares: Scalars['String']['input']
}

export type RemoveLiquidityFromPoolResult = {
  __typename?: 'RemoveLiquidityFromPoolResult'
  assetsOut: Array<Balance>
  pool: Pool
}

export type SimpleOrder = {
  __typename?: 'SimpleOrder'
  amountIn: Scalars['String']['output']
  amountOut: Scalars['String']['output']
  assetInID: Scalars['String']['output']
  assetOutID: Scalars['String']['output']
  orderBookID: Scalars['String']['output']
  price: Scalars['Float']['output']
  type: OrderType
}

export type Subscription = {
  __typename?: 'Subscription'
  candlesUpdated: Array<Candle>
  orderBookUpdated?: Maybe<OrdersSizeResponse>
  poolBalancesUpdated: Pool
  userBalancesUpdated: UserBalances
  userOrderChanged?: Maybe<Array<Maybe<Order>>>
}

export type SubscriptionCandlesUpdatedArgs = {
  marketID: Scalars['String']['input']
  resolution: Scalars['String']['input']
}

export type SubscriptionOrderBookUpdatedArgs = {
  orderBookID: Scalars['String']['input']
}

export type SubscriptionUserBalancesUpdatedArgs = {
  userUID: Scalars['String']['input']
}

export type SubscriptionUserOrderChangedArgs = {
  orderBookID: Scalars['String']['input']
  userUID: Scalars['String']['input']
}

export type SwapInput = {
  assetIn: BalanceInput
  minAmtOut: BalanceInput
  poolUID: Scalars['String']['input']
}

export type SwapResult = {
  __typename?: 'SwapResult'
  balanceOut: Balance
  fee: Balance
  pool: Pool
  tx: Transaction
}

export type TickerPrice = {
  __typename?: 'TickerPrice'
  baseAssetUID: Scalars['String']['output']
  lastPrice: Scalars['String']['output']
  quoteAssetUID: Scalars['String']['output']
  timestamp: Scalars['Int']['output']
  volume: Scalars['Int']['output']
  volume24h: Scalars['Int']['output']
}

export type Trade = {
  __typename?: 'Trade'
  amountIn: Scalars['Int']['output']
  amountOut: Scalars['Int']['output']
  assetInUID: Scalars['String']['output']
  assetOutUID: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  match: TradeMatch
  orderBookUID: Scalars['String']['output']
  poolUID: Scalars['String']['output']
  priceInToOut: Scalars['String']['output']
  priceOutToIn: Scalars['String']['output']
  txID: Scalars['String']['output']
  uid: Scalars['String']['output']
  userAssetInUID: Scalars['String']['output']
  userAssetOutUID: Scalars['String']['output']
}

export enum TradeMatch {
  OrderBookMatch = 'OrderBookMatch',
  PoolTrade = 'PoolTrade',
}

export enum TradeStatus {
  Closed = 'CLOSED',
  Liquidated = 'LIQUIDATED',
  Open = 'OPEN',
}

export enum TradeType {
  Long = 'LONG',
  Short = 'SHORT',
}

export type TradedVolume = {
  __typename?: 'TradedVolume'
  assetInID: Scalars['String']['output']
  assetOutID: Scalars['String']['output']
  fromUnixTime: Scalars['Int']['output']
  volume: Scalars['Int']['output']
}

export type TraderBot = {
  __typename?: 'TraderBot'
  amountList: Array<Scalars['Int']['output']>
  amountMult: Scalars['Int']['output']
  bidExePercentage: Scalars['Int']['output']
  botID: Scalars['String']['output']
  createdAt: Scalars['Int']['output']
  deltaLevels: Array<DeltaLevel>
  deltaMults: Array<DeltaMult>
  dps: Scalars['Int']['output']
  levels: Scalars['Int']['output']
  marketOrderFilePath: Scalars['String']['output']
  maxAskPrice: Scalars['Float']['output']
  maxBidPercentage: Scalars['Float']['output']
  maxSkew: Scalars['Float']['output']
  maxSwapAmount: Scalars['Int']['output']
  maxTakePercentage: Scalars['Float']['output']
  minBidPrice: Scalars['Float']['output']
  minDelayBetweenOrders: Scalars['Int']['output']
  minSkew: Scalars['Float']['output']
  minSwapAmount: Scalars['Int']['output']
  minTakePercentage: Scalars['Float']['output']
  name: Scalars['String']['output']
  option: TraderStrategyOption
  orderBookID: Scalars['String']['output']
  orderInterval: Scalars['Int']['output']
  orderQuantity: Scalars['Int']['output']
  spread: Scalars['Float']['output']
  strategyName: TraderStrategyName
  swapQuantity: Scalars['Int']['output']
  targetPrice: Scalars['Float']['output']
  tickSize: Scalars['Float']['output']
  updatedAt: Scalars['Int']['output']
  updatedBy: Scalars['String']['output']
}

export enum TraderStrategyName {
  Market = 'Market',
  Taker = 'Taker',
  TargetPrice = 'TargetPrice',
}

export enum TraderStrategyOption {
  Levels = 'Levels',
  Mults = 'Mults',
  Random = 'Random',
}

export type Transaction = {
  __typename?: 'Transaction'
  accrueInterest?: Maybe<TxAccrueInterest>
  addLiquidity?: Maybe<TxAddLiquidity>
  assetsUID?: Maybe<Array<Scalars['String']['output']>>
  claimInterest?: Maybe<TxClaimInterest>
  createdAt: Scalars['Int']['output']
  externalDeposit?: Maybe<TxExternalDeposit>
  externalWithdraw?: Maybe<TxExternalWithdraw>
  liquidate?: Maybe<TxLiquidate>
  matchOrder?: Maybe<TxMatchOrder>
  orderBookID: Scalars['String']['output']
  payInterest?: Maybe<TxPayInterest>
  removeLiquidity?: Maybe<TxRemoveLiquidity>
  repay?: Maybe<TxRepay>
  /**
   * These name=transaction-type fields) are null unless the transaction is of the specified type.
   * Only one will be non-null.
   */
  supply?: Maybe<TxSupply>
  swap?: Maybe<TxSwap>
  txID: Scalars['String']['output']
  type: TransactionType
  userUID: Scalars['String']['output']
  withdraw?: Maybe<TxWithdraw>
}

export enum TransactionType {
  AccrueInterest = 'AccrueInterest',
  AddLiquidity = 'AddLiquidity',
  ClaimInterest = 'ClaimInterest',
  ExternalDeposit = 'ExternalDeposit',
  ExternalWithdraw = 'ExternalWithdraw',
  Liquidate = 'Liquidate',
  MatchOrder = 'MatchOrder',
  PayInterest = 'PayInterest',
  RemoveLiquidity = 'RemoveLiquidity',
  Repay = 'Repay',
  Supply = 'Supply',
  Swap = 'Swap',
  Withdraw = 'Withdraw',
}

export type TxAccrueInterest = {
  __typename?: 'TxAccrueInterest'
  earned: Scalars['String']['output']
  fromUnixTime: Scalars['Int']['output']
  owed: Scalars['String']['output']
  toUnixTime: Scalars['Int']['output']
}

export type TxAddLiquidity = {
  __typename?: 'TxAddLiquidity'
  balancesIn: Array<Balance>
  sharesOut: Balance
}

export type TxClaimInterest = {
  __typename?: 'TxClaimInterest'
  balanceOut: Balance
  claimed: Scalars['String']['output']
}

export type TxExternalDeposit = {
  __typename?: 'TxExternalDeposit'
  balanceIn: Balance
  txHex: Scalars['String']['output']
}

export type TxExternalWithdraw = {
  __typename?: 'TxExternalWithdraw'
  balanceOut: Balance
  txHex: Scalars['String']['output']
}

export type TxLiquidate = {
  __typename?: 'TxLiquidate'
  /** BalanceIn is the swap balance in, and also the collateral which was lost by the target user */
  balanceIn: Balance
  /** BalanceOut is the swap balance out, and also the debt which was repaid on behalf of the target user */
  balanceOut: Balance
}

/**
 * TxMatchOrder is generated when two orders match, so orderbook activity appears in transaction history.
 * Two mirror copies of this transaction are generated (one on each user) for it to appear in both tx histories.
 */
export type TxMatchOrder = {
  __typename?: 'TxMatchOrder'
  /** BalanceIn is the balance lost by this user */
  balanceIn: Balance
  /** BalanceOut is the balance gained by this user */
  balanceOut: Balance
  /** Borrowed is the balance borrowed by this user to augment balanceIn, if leverage was used. Empty otherwise. */
  borrowed?: Maybe<Balance>
  /** CounterOrderID is the other order which was matched. */
  counterOrderID: Scalars['String']['output']
  /** CounterUserID is the user's ID of the counter order. */
  counterUserID: Scalars['String']['output']
  /** OrderID is the user's order which was matched. */
  orderID: Scalars['String']['output']
  /** Price is the actual price at execution of this order match */
  price: Scalars['Float']['output']
}

export type TxPayInterest = {
  __typename?: 'TxPayInterest'
  balanceIn: Balance
  paid: Scalars['String']['output']
}

export type TxRemoveLiquidity = {
  __typename?: 'TxRemoveLiquidity'
  balancesOut: Array<Balance>
  sharesIn: Balance
}

export type TxRepay = {
  __typename?: 'TxRepay'
  balanceIn: Balance
}

export type TxSupply = {
  __typename?: 'TxSupply'
  balanceIn: Balance
}

export type TxSwap = {
  __typename?: 'TxSwap'
  balanceIn: Balance
  balanceOut: Balance
  borrowed?: Maybe<Balance>
  /** orderID is the ID the associated order which causes this swap to execute */
  orderID: Scalars['String']['output']
  price: Scalars['Float']['output']
}

export type TxWithdraw = {
  __typename?: 'TxWithdraw'
  balanceOut: Balance
}

export type UpdateAssetInput = {
  assetID: Scalars['String']['input']
  bond?: InputMaybe<BondInput>
  collateralWeight?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  liquidationThreshold?: InputMaybe<Scalars['String']['input']>
  maxSupply?: InputMaybe<Scalars['String']['input']>
  maxUtilization?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<AssetType>
  usage?: InputMaybe<Array<InputMaybe<AssetUsage>>>
}

export type UpdateExternalWithdrawStatusInput = {
  txId: Scalars['String']['input']
  txStatus: Scalars['Int']['input']
}

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  photoURL?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  providerID?: InputMaybe<Scalars['String']['input']>
  uid: Scalars['String']['input']
  wallets?: InputMaybe<Array<WalletInput>>
}

export type UpdateUserRolesInput = {
  roles: Array<UserRole>
  uid: Scalars['String']['input']
}

export type UpsertOrderBot = {
  amountList: Array<Scalars['Int']['input']>
  baseDecimals: Scalars['Int']['input']
  botID: Scalars['String']['input']
  customTick: Scalars['Float']['input']
  frequency: Scalars['Int']['input']
  levels: Scalars['Int']['input']
  maxMult: Scalars['Float']['input']
  minMult: Scalars['Float']['input']
  name: Scalars['String']['input']
  orderBookID: Scalars['String']['input']
  priceList: Array<Scalars['Float']['input']>
  quoteDecimals: Scalars['Int']['input']
  spread: Scalars['Float']['input']
  startingBaseAmount: Scalars['Float']['input']
  strategy: BotStrategy
}

export type UpsertTraderBot = {
  amountList: Array<Scalars['Int']['input']>
  amountMult: Scalars['Int']['input']
  bidExePercentage: Scalars['Int']['input']
  botID: Scalars['String']['input']
  deltaLevels: Array<DeltaLevelInput>
  deltaMults: Array<DeltaMultInput>
  dps: Scalars['Int']['input']
  levels: Scalars['Int']['input']
  marketOrderFilePath: Scalars['String']['input']
  maxAskPrice: Scalars['Float']['input']
  maxBidPercentage: Scalars['Float']['input']
  maxSkew: Scalars['Float']['input']
  maxSwapAmount: Scalars['Int']['input']
  maxTakePercentage: Scalars['Float']['input']
  minBidPrice: Scalars['Float']['input']
  minDelayBetweenOrders: Scalars['Int']['input']
  minSkew: Scalars['Float']['input']
  minSwapAmount: Scalars['Int']['input']
  minTakePercentage: Scalars['Float']['input']
  name: Scalars['String']['input']
  option: TraderStrategyOption
  orderBookID: Scalars['String']['input']
  orderInterval: Scalars['Int']['input']
  orderQuantity: Scalars['Int']['input']
  spread: Scalars['Float']['input']
  strategyName: TraderStrategyName
  swapQuantity: Scalars['Int']['input']
  targetPrice: Scalars['Float']['input']
  tickSize: Scalars['Float']['input']
}

export type User = {
  __typename?: 'User'
  accountClosed: Scalars['Boolean']['output']
  /**
   * Balances include any assets a user is currently in possession of.
   * This excludes those supplied to leverage or provided to pools, but includes pool shares.
   */
  balances: Array<Balance>
  /**
   * Borrowed contains any assets currently owed to the leverage module by the user, excluding interest.
   * In the case of bonds, these are virtual bonds minted by the leverage module using account value as collateral.
   * For stablecoins, this contains assets borrowed from the leverage module which were supplied by other users.
   */
  borrowed: Array<Balance>
  currency?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  /** History is a collection of all this user's transactions. */
  history: Array<Transaction>
  lastLoginDate: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
  /** NoOfAddFundsTxs is a total transactions count of AddFunds to the user on 24 hours.  */
  noOfAddFundsTxs: Scalars['Int']['output']
  photoURL?: Maybe<Scalars['String']['output']>
  provider?: Maybe<Scalars['String']['output']>
  providerID?: Maybe<Scalars['String']['output']>
  registrationDate: Scalars['Int']['output']
  roles: Array<UserRole>
  /**
   * Supplied contains stablecoins which a user has made available for borrowing by other users in exchange for earning interest.
   * These assets are no longer present in Balances until they are withdrawn from leverage.
   */
  supplied: Array<Balance>
  timezone?: Maybe<Scalars['String']['output']>
  /** UID uniquely identifies a user in the Users collection, and also appears in the user's orders and other associated data. */
  uid: Scalars['String']['output']
  walletInfo?: Maybe<WalletInfo>
  wallets?: Maybe<Array<Maybe<Wallet>>>
}

export type UserBalances = {
  __typename?: 'UserBalances'
  available: Array<Balance>
  borrowed: Array<Balance>
  locked: Array<Balance>
  supplied: Array<Balance>
  total: Array<Balance>
}

export type UserPositions = {
  __typename?: 'UserPositions'
  available: Array<Balance>
  borrowed: Array<Balance>
  locked: Array<Balance>
  supplied: Array<Balance>
  total: Array<Balance>
}

/** Users can have one or more roles */
export enum UserRole {
  Admin = 'Admin',
  /** BondTrader allows placing orders and borrowing, and adding liquidity to pools */
  BondTrader = 'BondTrader',
  SuperAdmin = 'SuperAdmin',
}

/** Wallet hold external chainID and address of user wallet */
export type Wallet = {
  __typename?: 'Wallet'
  address: Array<Scalars['String']['output']>
  chainID: Scalars['String']['output']
}

/** WalletInfo store the wallet address and user wallet secret version (which is stored in google secret manger) */
export type WalletInfo = {
  __typename?: 'WalletInfo'
  address: Scalars['String']['output']
  secretVerion: Scalars['String']['output']
}

export type WalletInput = {
  address: Array<Scalars['String']['input']>
  chainID: Scalars['String']['input']
}

export type WalletTx = {
  __typename?: 'WalletTx'
  chainId: Scalars['String']['output']
  coin: Coin
  effectiveGasPrice: Scalars['String']['output']
  fromHex: Scalars['String']['output']
  gasUsed: Scalars['String']['output']
  status: Scalars['String']['output']
  toHex: Scalars['String']['output']
  txHex: Scalars['String']['output']
}

export type YieldSpaceProp = {
  __typename?: 'YieldSpaceProp'
  g: Scalars['Float']['output']
  maturityAt: Scalars['Int']['output']
}

export type CreateAssetMutationVariables = Exact<{
  newAsset?: InputMaybe<CreateAssetInput>
}>

export type CreateAssetMutation = {
  __typename?: 'Mutation'
  CreateAsset?: { __typename?: 'Asset'; uid: string } | null
}

export type UpdateAssetMutationVariables = Exact<{
  updateAsset?: InputMaybe<UpdateAssetInput>
}>

export type UpdateAssetMutation = {
  __typename?: 'Mutation'
  UpdateAsset?: { __typename?: 'Asset'; uid: string } | null
}

export type UpsertOrderBotMutationVariables = Exact<{
  bot: UpsertOrderBot
}>

export type UpsertOrderBotMutation = { __typename?: 'Mutation'; UpsertOrderBot: boolean }

export type StartOrderBotMutationVariables = Exact<{
  botId: Scalars['String']['input']
}>

export type StartOrderBotMutation = { __typename?: 'Mutation'; StartOrderBot: boolean }

export type StopOrderBotMutationVariables = Exact<{
  botId: Scalars['String']['input']
}>

export type StopOrderBotMutation = { __typename?: 'Mutation'; StopOrderBot: boolean }

export type StartTraderBotMutationVariables = Exact<{
  botId: Scalars['String']['input']
}>

export type StartTraderBotMutation = { __typename?: 'Mutation'; StartTraderBot: boolean }

export type StopTraderBotMutationVariables = Exact<{
  botId: Scalars['String']['input']
}>

export type StopTraderBotMutation = { __typename?: 'Mutation'; StopTraderBot: boolean }

export type UpsertTraderBotMutationVariables = Exact<{
  bot: UpsertTraderBot
}>

export type UpsertTraderBotMutation = { __typename?: 'Mutation'; UpsertTraderBot: boolean }

export type CreateOrderWithPriceMutationVariables = Exact<{
  newOrder?: InputMaybe<CreateOrderWithPriceInput>
}>

export type CreateOrderWithPriceMutation = {
  __typename?: 'Mutation'
  CreateOrderWithPrice?: {
    __typename?: 'Order'
    advancedType: AdvancedOrderType
    createdAt: number
    inverseLeverage?: number | null
    orderBookID: string
    orderID: string
    price: number
    state: OrderState
    triggerPrice: number
    type: OrderType
    updatedAt: number
    userUID: string
  } | null
}

export type CreateAdvancedOrderMutationVariables = Exact<{
  input?: InputMaybe<CreateAdvancedOrderInput>
}>

export type CreateAdvancedOrderMutation = {
  __typename?: 'Mutation'
  CreateAdvancedOrder?: {
    __typename?: 'Order'
    advancedType: AdvancedOrderType
    amountIn: string
    amountOut: string
    assetInID: string
    assetOutID: string
    createdAt: number
    inFilled: string
    inverseLeverage?: number | null
    orderBookID: string
    orderID: string
    outFilled: string
    price: number
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
    state: OrderState
    triggerPrice: number
    type: OrderType
    updatedAt: number
    userUID: string
  } | null
}

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput
}>

export type CancelOrderMutation = { __typename?: 'Mutation'; CancelOrder: boolean }

export type CreatePoolMutationVariables = Exact<{
  input?: InputMaybe<CreatePoolInput>
}>

export type CreatePoolMutation = {
  __typename?: 'Mutation'
  CreatePool?: { __typename?: 'PoolAsset'; pool: { __typename?: 'Pool'; uid: string } } | null
}

export type AddLiquidityToPoolMutationVariables = Exact<{
  addLiquidity?: InputMaybe<AddLiquidityPoolInput>
}>

export type AddLiquidityToPoolMutation = {
  __typename?: 'Mutation'
  AddLiquidityToPool?: { __typename?: 'PoolBalance'; pool: { __typename?: 'Pool'; uid: string } } | null
}

export type RemoveLiquidityFromPoolMutationVariables = Exact<{
  removeLiquidity?: InputMaybe<RemoveLiquidityFromPoolInput>
}>

export type RemoveLiquidityFromPoolMutation = {
  __typename?: 'Mutation'
  RemoveLiquidityFromPool?: {
    __typename?: 'RemoveLiquidityFromPoolResult'
    assetsOut: Array<{ __typename?: 'Balance'; amount: string; assetID: string }>
    pool: { __typename?: 'Pool'; uid: string }
  } | null
}

export type AddFundsToUserMutationVariables = Exact<{
  addFunds?: InputMaybe<AddFundsToUserInput>
}>

export type AddFundsToUserMutation = { __typename?: 'Mutation'; AddFundsToUser: boolean }

export type UpdateUserMutationVariables = Exact<{
  updatedUser?: InputMaybe<UpdateUserInput>
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  UpdateUser?: {
    __typename?: 'User'
    email: string
    name?: string | null
    photoURL?: string | null
    roles: Array<UserRole>
  } | null
}

export type AddBalancesMutationVariables = Exact<{
  balanceToAdd?: InputMaybe<BalanceInput>
}>

export type AddBalancesMutation = {
  __typename?: 'Mutation'
  AddBalances?: { __typename?: 'Balance'; amount: string; assetID: string } | null
}

export type RemoveBalancesMutationVariables = Exact<{
  balanceToRmv?: InputMaybe<BalanceInput>
}>

export type RemoveBalancesMutation = {
  __typename?: 'Mutation'
  RemoveBalances?: { __typename?: 'Balance'; amount: string; assetID: string } | null
}

export type SupplyMutationVariables = Exact<{
  asset: BalanceInput
}>

export type SupplyMutation = {
  __typename?: 'Mutation'
  Supply: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type CollateralizeMutationVariables = Exact<{
  asset: BalanceInput
}>

export type CollateralizeMutation = {
  __typename?: 'Mutation'
  Supply: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type WithdrawMutationVariables = Exact<{
  asset: BalanceInput
}>

export type WithdrawMutation = {
  __typename?: 'Mutation'
  Withdraw: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type RepayMutationVariables = Exact<{
  asset: BalanceInput
}>

export type RepayMutation = {
  __typename?: 'Mutation'
  Repay: { __typename?: 'Transaction'; txID: string; type: TransactionType; userUID: string }
}

export type AddBalanceToUserMutationVariables = Exact<{
  balanceToUserInput?: InputMaybe<BalanceToUserInput>
}>

export type AddBalanceToUserMutation = {
  __typename?: 'Mutation'
  AddBalanceToUser?: { __typename?: 'Balance'; amount: string; assetID: string } | null
}

export type RemoveBalanceFromUserMutationVariables = Exact<{
  balanceToUserInput?: InputMaybe<BalanceToUserInput>
}>

export type RemoveBalanceFromUserMutation = {
  __typename?: 'Mutation'
  RemoveBalanceFromUser?: { __typename?: 'Balance'; amount: string; assetID: string } | null
}

export type DeleteMeMutationVariables = Exact<{ [key: string]: never }>

export type DeleteMeMutation = { __typename?: 'Mutation'; DeleteMe: boolean }

export type ExternalDepositMutationVariables = Exact<{
  deposit: ExternalDepositInput
}>

export type ExternalDepositMutation = {
  __typename?: 'Mutation'
  ExternalDeposit?: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number } | null
}

export type ExternalWithdrawMutationVariables = Exact<{
  input: ExternalWithdrawInput
}>

export type ExternalWithdrawMutation = { __typename?: 'Mutation'; ExternalWithdraw: string }

export type GetAssetQueryVariables = Exact<{
  assetId: Scalars['String']['input']
}>

export type GetAssetQuery = {
  __typename?: 'Query'
  getAsset: {
    __typename?: 'Asset'
    uid: string
    decimals: number
    description: string
    symbol: string
    type: AssetType
    usage: Array<AssetUsage>
    collateralWeight: string
    liquidationThreshold: string
    maxUtilization: string
    maxSupply: string
    createdAt: number
    bond?: {
      __typename?: 'AssetBond'
      name: string
      issuer: string
      isin: string
      coupon?: number | null
      couponFrequency?: CouponFrequency | null
      assetBondType?: AssetBondType | null
      ticker?: string | null
      yield: number
      numberOfPaymentsPerYear: number
      couponStartDate: string
      finalCouponDate: string
      issueDate: string
      maturityDate: string
      cusip?: string | null
      bondDetailsUrl?: string | null
      priceHistoryUrl?: string | null
      educationUrl?: string | null
    } | null
  }
}

export type GetAllAssetsQueryVariables = Exact<{
  pagination: Pagination
}>

export type GetAllAssetsQuery = {
  __typename?: 'Query'
  getAllAssets: Array<{
    __typename?: 'Asset'
    uid: string
    decimals: number
    description: string
    symbol: string
    type: AssetType
    usage: Array<AssetUsage>
    collateralWeight: string
    liquidationThreshold: string
    maxUtilization: string
    maxSupply: string
    bond?: {
      __typename?: 'AssetBond'
      name: string
      issuer: string
      isin: string
      coupon?: number | null
      couponFrequency?: CouponFrequency | null
      assetBondType?: AssetBondType | null
      ticker?: string | null
      yield: number
      numberOfPaymentsPerYear: number
      couponStartDate: string
      finalCouponDate: string
      issueDate: string
      maturityDate: string
      cusip?: string | null
      bondDetailsUrl?: string | null
      priceHistoryUrl?: string | null
      educationUrl?: string | null
    } | null
  }>
}

export type GetCandlesQueryVariables = Exact<{
  assetInUid: Scalars['String']['input']
  assetOutUid: Scalars['String']['input']
  from: Scalars['Int']['input']
  to: Scalars['Int']['input']
  resolution: Scalars['String']['input']
}>

export type GetCandlesQuery = {
  __typename?: 'Query'
  getCandles: Array<{
    __typename?: 'Candle'
    time: number
    open: number
    close: number
    high: number
    low: number
    volume: number
  }>
}

export type GetAppEnvQueryVariables = Exact<{ [key: string]: never }>

export type GetAppEnvQuery = { __typename?: 'Query'; getAppEnv: string }

export type ExploreTxsQueryVariables = Exact<{
  txTypes?: InputMaybe<Array<TransactionType> | TransactionType>
  orderBookId?: InputMaybe<Scalars['String']['input']>
  from?: InputMaybe<Scalars['Int']['input']>
  to?: InputMaybe<Scalars['Int']['input']>
  userUid?: InputMaybe<Scalars['String']['input']>
  assetsUid?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  txId?: InputMaybe<Scalars['String']['input']>
  pagination?: InputMaybe<Pagination>
}>

export type ExploreTxsQuery = {
  __typename?: 'Query'
  exploreTxs?: Array<{
    __typename?: 'Transaction'
    assetsUID?: Array<string> | null
    createdAt: number
    orderBookID: string
    txID: string
    type: TransactionType
    userUID: string
    accrueInterest?: {
      __typename?: 'TxAccrueInterest'
      fromUnixTime: number
      toUnixTime: number
      earned: string
      owed: string
    } | null
    claimInterest?: {
      __typename?: 'TxClaimInterest'
      claimed: string
      balanceOut: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    liquidate?: {
      __typename?: 'TxLiquidate'
      balanceIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
      balanceOut: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    matchOrder?: {
      __typename?: 'TxMatchOrder'
      orderID: string
      counterOrderID: string
      counterUserID: string
      price: number
      balanceIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
      borrowed?: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number } | null
      balanceOut: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    payInterest?: {
      __typename?: 'TxPayInterest'
      paid: string
      balanceIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    repay?: {
      __typename?: 'TxRepay'
      balanceIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    supply?: {
      __typename?: 'TxSupply'
      balanceIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    swap?: {
      __typename?: 'TxSwap'
      price: number
      orderID: string
      balanceIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
      borrowed?: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number } | null
      balanceOut: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    withdraw?: {
      __typename?: 'TxWithdraw'
      balanceOut: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    addLiquidity?: {
      __typename?: 'TxAddLiquidity'
      balancesIn: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
      sharesOut: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    externalDeposit?: {
      __typename?: 'TxExternalDeposit'
      txHex: string
      balanceIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    externalWithdraw?: {
      __typename?: 'TxExternalWithdraw'
      txHex: string
      balanceOut: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    } | null
    removeLiquidity?: {
      __typename?: 'TxRemoveLiquidity'
      sharesIn: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
      balancesOut: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    } | null
  } | null> | null
}

export type ModulePositionsQueryVariables = Exact<{ [key: string]: never }>

export type ModulePositionsQuery = {
  __typename?: 'Query'
  modulePositions?: {
    __typename?: 'ModulePositions'
    balance: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    supplied: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    borrowed: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    virtual: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  } | null
}

export type OrderBotsQueryVariables = Exact<{
  botId?: InputMaybe<Scalars['String']['input']>
}>

export type OrderBotsQuery = {
  __typename?: 'Query'
  orderBots: Array<{
    __typename?: 'OrderBot'
    botID: string
    name: string
    orderBookID: string
    strategy: BotStrategy
    spread: number
    levels: number
    customTick: number
    minMult: number
    maxMult: number
    baseDecimals: number
    quoteDecimals: number
    startingBaseAmount: number
    frequency: number
    amountList: Array<number>
    priceList: Array<number>
    createdAt: number
    updatedAt: number
    updatedBy: string
  }>
}

export type TraderBotsQueryVariables = Exact<{
  botId?: InputMaybe<Scalars['String']['input']>
}>

export type TraderBotsQuery = {
  __typename?: 'Query'
  traderBots: Array<{
    __typename?: 'TraderBot'
    botID: string
    name: string
    orderBookID: string
    minSwapAmount: number
    maxSwapAmount: number
    orderQuantity: number
    swapQuantity: number
    bidExePercentage: number
    orderInterval: number
    minDelayBetweenOrders: number
    strategyName: TraderStrategyName
    spread: number
    levels: number
    minTakePercentage: number
    maxTakePercentage: number
    maxBidPercentage: number
    dps: number
    minSkew: number
    maxSkew: number
    minBidPrice: number
    maxAskPrice: number
    tickSize: number
    amountMult: number
    targetPrice: number
    amountList: Array<number>
    option: TraderStrategyOption
    marketOrderFilePath: string
    createdAt: number
    updatedAt: number
    updatedBy: string
    deltaLevels: Array<{ __typename?: 'DeltaLevel'; delta: number; level: number }>
    deltaMults: Array<{ __typename?: 'DeltaMult'; delta: number; mult: number }>
  }>
}

export type GetAllOrderBooksQueryVariables = Exact<{ [key: string]: never }>

export type GetAllOrderBooksQuery = {
  __typename?: 'Query'
  getAllOrderBooks: Array<{
    __typename?: 'OrderBook'
    orderBookID: string
    baseUID: string
    quoteUID: string
    tickSize: number
    priceDisplayMultiplier: number
    orders: Array<{
      __typename?: 'SimpleOrder'
      orderBookID: string
      assetInID: string
      assetOutID: string
      amountIn: string
      amountOut: string
      price: number
      type: OrderType
    }>
  }>
}

export type OrderBookOpenOrdersQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type OrderBookOpenOrdersQuery = {
  __typename?: 'Query'
  orderBookOpenOrders: {
    __typename?: 'OrdersSizeResponse'
    buys: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
    sells: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
  }
}

export type UserLeveragePositionsQueryVariables = Exact<{ [key: string]: never }>

export type UserLeveragePositionsQuery = {
  __typename?: 'Query'
  userLeveragePositions?: {
    __typename?: 'LeveragePosition'
    borrowed: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    supplied: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  } | null
}

export type GetAllPoolsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>
}>

export type GetAllPoolsQuery = {
  __typename?: 'Query'
  getAllPools: Array<{
    __typename?: 'Pool'
    uid: string
    type: PoolType
    createdAt: number
    baseAsset: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    quoteAsset: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    poolShares: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    yieldSpaceProp?: { __typename?: 'YieldSpaceProp'; g: number; maturityAt: number } | null
    prodProp?: { __typename?: 'ProductProperties'; swapFee: number } | null
  }>
}

export type GetPoolOrdersSummaryQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type GetPoolOrdersSummaryQuery = {
  __typename?: 'Query'
  getPoolOrdersSummary: { __typename?: 'PoolOrdersSummary'; midPrice: number; orderBookID: string; spread: number }
}

export type GetTradesQueryVariables = Exact<{
  assetInUid: Scalars['String']['input']
  assetOutUid: Scalars['String']['input']
  all: Scalars['Boolean']['input']
  pagination?: InputMaybe<Pagination>
}>

export type GetTradesQuery = {
  __typename?: 'Query'
  getTrades: Array<{
    __typename?: 'Trade'
    uid: string
    assetInUID: string
    assetOutUID: string
    amountIn: number
    amountOut: number
    priceInToOut: string
    priceOutToIn: string
    match: TradeMatch
    createdAt: number
    poolUID: string
    userAssetInUID: string
    orderBookUID: string
    userAssetOutUID: string
    txID: string
  }>
}

export type InitUserQueryVariables = Exact<{ [key: string]: never }>

export type InitUserQuery = {
  __typename?: 'Query'
  initUser?: {
    __typename?: 'User'
    uid: string
    email: string
    name?: string | null
    photoURL?: string | null
    provider?: string | null
    providerID?: string | null
    roles: Array<UserRole>
    registrationDate: number
    lastLoginDate: number
    accountClosed: boolean
    timezone?: string | null
    currency?: string | null
    noOfAddFundsTxs: number
    wallets?: Array<{ __typename?: 'Wallet'; address: Array<string>; chainID: string } | null> | null
  } | null
}

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>

export type GetAllUsersQuery = {
  __typename?: 'Query'
  getAllUsers: Array<{
    __typename?: 'User'
    uid: string
    roles: Array<UserRole>
    name?: string | null
    email: string
    provider?: string | null
    registrationDate: number
    lastLoginDate: number
    balances: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    borrowed: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    supplied: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  }>
}

export type UpdateUsersRolesMutationVariables = Exact<{
  updatedRoles?: InputMaybe<UpdateUserRolesInput>
}>

export type UpdateUsersRolesMutation = {
  __typename?: 'Mutation'
  UpdateUsersRoles?: { __typename?: 'User'; uid: string; roles: Array<UserRole> } | null
}

export type UserOrderBookOrdersQueryVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type UserOrderBookOrdersQuery = {
  __typename?: 'Query'
  userOrderBookOrders: Array<{
    __typename?: 'Order'
    advancedType: AdvancedOrderType
    amountIn: string
    amountOut: string
    assetInID: string
    assetOutID: string
    createdAt: number
    inFilled: string
    inverseLeverage?: number | null
    orderBookID: string
    orderID: string
    outFilled: string
    price: number
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
    state: OrderState
    triggerPrice: number
    type: OrderType
    updatedAt: number
    userUID: string
  }>
}

export type UserBalancesQueryVariables = Exact<{ [key: string]: never }>

export type UserBalancesQuery = {
  __typename?: 'Query'
  userBalances?: {
    __typename?: 'UserBalances'
    total: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    available: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    locked: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    borrowed: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    supplied: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  } | null
}

export type GetAllUserPositionsQueryVariables = Exact<{
  userUid: Scalars['String']['input']
}>

export type GetAllUserPositionsQuery = {
  __typename?: 'Query'
  getAllUserPositions: {
    __typename?: 'UserPositions'
    total: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    available: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    locked: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    supplied: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    borrowed: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  }
}

export type UserLeverageValueQueryVariables = Exact<{
  userUid: Scalars['String']['input']
}>

export type UserLeverageValueQuery = {
  __typename?: 'Query'
  userLeverageValue: {
    __typename?: 'LeverageValue'
    supplied: number
    collateral: number
    borrowed: number
    borrowLimit: number
    liquidationThreshold: number
  }
}

export type EstimateGasQueryVariables = Exact<{
  estimateGasInput?: InputMaybe<ExternalWithdrawInput>
}>

export type EstimateGasQuery = { __typename?: 'Query'; estimateGas: number }

export type OrderBookUpdatedSubscriptionVariables = Exact<{
  orderBookId: Scalars['String']['input']
}>

export type OrderBookUpdatedSubscription = {
  __typename?: 'Subscription'
  orderBookUpdated?: {
    __typename?: 'OrdersSizeResponse'
    sells: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
    buys: Array<{ __typename?: 'OrdersSize'; price: number; size: number }>
  } | null
}

export type UserOrderChangedSubscriptionVariables = Exact<{
  orderBookId: Scalars['String']['input']
  userUid: Scalars['String']['input']
}>

export type UserOrderChangedSubscription = {
  __typename?: 'Subscription'
  userOrderChanged?: Array<{
    __typename?: 'Order'
    advancedType: AdvancedOrderType
    amountIn: string
    amountOut: string
    assetInID: string
    assetOutID: string
    createdAt: number
    inFilled: string
    inverseLeverage?: number | null
    orderBookID: string
    orderID: string
    outFilled: string
    price: number
    sortAmountIn: number
    sortAmountOut: number
    sortInFilled: number
    sortOutFilled: number
    sortRemainingAmountIn: number
    state: OrderState
    triggerPrice: number
    type: OrderType
    updatedAt: number
    userUID: string
  } | null> | null
}

export type CandlesUpdatedSubscriptionVariables = Exact<{
  marketId: Scalars['String']['input']
  resolution: Scalars['String']['input']
}>

export type CandlesUpdatedSubscription = {
  __typename?: 'Subscription'
  candlesUpdated: Array<{
    __typename?: 'Candle'
    close: number
    high: number
    low: number
    open: number
    time: number
    volume: number
  }>
}

export type PoolBalancesUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>

export type PoolBalancesUpdatedSubscription = {
  __typename?: 'Subscription'
  poolBalancesUpdated: {
    __typename?: 'Pool'
    uid: string
    type: PoolType
    createdAt: number
    baseAsset: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    quoteAsset: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    poolShares: { __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }
    yieldSpaceProp?: { __typename?: 'YieldSpaceProp'; g: number; maturityAt: number } | null
    prodProp?: { __typename?: 'ProductProperties'; swapFee: number } | null
  }
}

export type UserBalancesUpdatedSubscriptionVariables = Exact<{
  userUid: Scalars['String']['input']
}>

export type UserBalancesUpdatedSubscription = {
  __typename?: 'Subscription'
  userBalancesUpdated: {
    __typename?: 'UserBalances'
    total: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    available: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    locked: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    borrowed: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
    supplied: Array<{ __typename?: 'Balance'; assetID: string; amount: string; sortAmount: number }>
  }
}

export const CreateAssetDocument = gql`
  mutation CreateAsset($newAsset: CreateAssetInput) {
    CreateAsset(newAsset: $newAsset) {
      uid
    }
  }
`
export const UpdateAssetDocument = gql`
  mutation UpdateAsset($updateAsset: UpdateAssetInput) {
    UpdateAsset(updateAsset: $updateAsset) {
      uid
    }
  }
`
export const UpsertOrderBotDocument = gql`
  mutation UpsertOrderBot($bot: UpsertOrderBot!) {
    UpsertOrderBot(bot: $bot)
  }
`
export const StartOrderBotDocument = gql`
  mutation StartOrderBot($botId: String!) {
    StartOrderBot(botID: $botId)
  }
`
export const StopOrderBotDocument = gql`
  mutation StopOrderBot($botId: String!) {
    StopOrderBot(botID: $botId)
  }
`
export const StartTraderBotDocument = gql`
  mutation StartTraderBot($botId: String!) {
    StartTraderBot(botID: $botId)
  }
`
export const StopTraderBotDocument = gql`
  mutation StopTraderBot($botId: String!) {
    StopTraderBot(botID: $botId)
  }
`
export const UpsertTraderBotDocument = gql`
  mutation UpsertTraderBot($bot: UpsertTraderBot!) {
    UpsertTraderBot(bot: $bot)
  }
`
export const CreateOrderWithPriceDocument = gql`
  mutation CreateOrderWithPrice($newOrder: CreateOrderWithPriceInput) {
    CreateOrderWithPrice(newOrder: $newOrder) {
      advancedType
      createdAt
      inverseLeverage
      orderBookID
      orderID
      price
      state
      triggerPrice
      type
      updatedAt
      userUID
    }
  }
`
export const CreateAdvancedOrderDocument = gql`
  mutation CreateAdvancedOrder($input: CreateAdvancedOrderInput) {
    CreateAdvancedOrder(input: $input) {
      advancedType
      amountIn
      amountOut
      assetInID
      assetOutID
      createdAt
      inFilled
      inverseLeverage
      orderBookID
      orderID
      outFilled
      price
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      state
      triggerPrice
      type
      updatedAt
      userUID
    }
  }
`
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    CancelOrder(input: $input)
  }
`
export const CreatePoolDocument = gql`
  mutation CreatePool($input: CreatePoolInput) {
    CreatePool(input: $input) {
      pool {
        uid
      }
    }
  }
`
export const AddLiquidityToPoolDocument = gql`
  mutation AddLiquidityToPool($addLiquidity: AddLiquidityPoolInput) {
    AddLiquidityToPool(addLiquidity: $addLiquidity) {
      pool {
        uid
      }
    }
  }
`
export const RemoveLiquidityFromPoolDocument = gql`
  mutation RemoveLiquidityFromPool($removeLiquidity: RemoveLiquidityFromPoolInput) {
    RemoveLiquidityFromPool(removeLiquidity: $removeLiquidity) {
      assetsOut {
        amount
        assetID
      }
      pool {
        uid
      }
    }
  }
`
export const AddFundsToUserDocument = gql`
  mutation AddFundsToUser($addFunds: AddFundsToUserInput) {
    AddFundsToUser(addFunds: $addFunds)
  }
`
export const UpdateUserDocument = gql`
  mutation UpdateUser($updatedUser: UpdateUserInput) {
    UpdateUser(updatedUser: $updatedUser) {
      email
      name
      photoURL
      roles
    }
  }
`
export const AddBalancesDocument = gql`
  mutation AddBalances($balanceToAdd: BalanceInput) {
    AddBalances(balanceToAdd: $balanceToAdd) {
      amount
      assetID
    }
  }
`
export const RemoveBalancesDocument = gql`
  mutation RemoveBalances($balanceToRmv: BalanceInput) {
    RemoveBalances(balanceToRmv: $balanceToRmv) {
      amount
      assetID
    }
  }
`
export const SupplyDocument = gql`
  mutation Supply($asset: BalanceInput!) {
    Supply(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const CollateralizeDocument = gql`
  mutation Collateralize($asset: BalanceInput!) {
    Supply(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const WithdrawDocument = gql`
  mutation Withdraw($asset: BalanceInput!) {
    Withdraw(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const RepayDocument = gql`
  mutation Repay($asset: BalanceInput!) {
    Repay(asset: $asset) {
      txID
      type
      userUID
    }
  }
`
export const AddBalanceToUserDocument = gql`
  mutation AddBalanceToUser($balanceToUserInput: BalanceToUserInput) {
    AddBalanceToUser(balanceToUser: $balanceToUserInput) {
      amount
      assetID
    }
  }
`
export const RemoveBalanceFromUserDocument = gql`
  mutation RemoveBalanceFromUser($balanceToUserInput: BalanceToUserInput) {
    RemoveBalanceFromUser(balanceToUser: $balanceToUserInput) {
      amount
      assetID
    }
  }
`
export const DeleteMeDocument = gql`
  mutation DeleteMe {
    DeleteMe
  }
`
export const ExternalDepositDocument = gql`
  mutation ExternalDeposit($deposit: ExternalDepositInput!) {
    ExternalDeposit(deposit: $deposit) {
      assetID
      amount
      sortAmount
    }
  }
`
export const ExternalWithdrawDocument = gql`
  mutation ExternalWithdraw($input: ExternalWithdrawInput!) {
    ExternalWithdraw(input: $input)
  }
`
export const GetAssetDocument = gql`
  query GetAsset($assetId: String!) {
    getAsset(assetID: $assetId) {
      uid
      decimals
      description
      symbol
      type
      usage
      collateralWeight
      liquidationThreshold
      maxUtilization
      maxSupply
      bond {
        name
        issuer
        isin
        coupon
        couponFrequency
        assetBondType
        ticker
        yield
        numberOfPaymentsPerYear
        couponStartDate
        finalCouponDate
        issueDate
        maturityDate
        cusip
        bondDetailsUrl
        priceHistoryUrl
        educationUrl
      }
      createdAt
    }
  }
`
export const GetAllAssetsDocument = gql`
  query GetAllAssets($pagination: Pagination!) {
    getAllAssets(pagination: $pagination) {
      uid
      decimals
      description
      symbol
      type
      usage
      collateralWeight
      liquidationThreshold
      maxUtilization
      maxSupply
      bond {
        name
        issuer
        isin
        coupon
        couponFrequency
        assetBondType
        ticker
        yield
        numberOfPaymentsPerYear
        couponStartDate
        finalCouponDate
        issueDate
        maturityDate
        cusip
        bondDetailsUrl
        priceHistoryUrl
        educationUrl
      }
    }
  }
`
export const GetCandlesDocument = gql`
  query GetCandles($assetInUid: String!, $assetOutUid: String!, $from: Int!, $to: Int!, $resolution: String!) {
    getCandles(assetInUID: $assetInUid, assetOutUID: $assetOutUid, from: $from, to: $to, resolution: $resolution) {
      time
      open
      close
      high
      low
      volume
    }
  }
`
export const GetAppEnvDocument = gql`
  query GetAppEnv {
    getAppEnv
  }
`
export const ExploreTxsDocument = gql`
  query ExploreTxs(
    $txTypes: [TransactionType!]
    $orderBookId: String
    $from: Int
    $to: Int
    $userUid: String
    $assetsUid: [String!]
    $txId: String
    $pagination: Pagination
  ) {
    exploreTxs(
      txTypes: $txTypes
      orderBookID: $orderBookId
      from: $from
      to: $to
      userUID: $userUid
      assetsUID: $assetsUid
      txID: $txId
      pagination: $pagination
    ) {
      accrueInterest {
        fromUnixTime
        toUnixTime
        earned
        owed
      }
      assetsUID
      claimInterest {
        balanceOut {
          assetID
          amount
          sortAmount
        }
        claimed
      }
      createdAt
      liquidate {
        balanceIn {
          assetID
          amount
          sortAmount
        }
        balanceOut {
          assetID
          amount
          sortAmount
        }
      }
      matchOrder {
        orderID
        counterOrderID
        counterUserID
        balanceIn {
          assetID
          amount
          sortAmount
        }
        borrowed {
          assetID
          amount
          sortAmount
        }
        balanceOut {
          assetID
          amount
          sortAmount
        }
        price
      }
      orderBookID
      payInterest {
        balanceIn {
          assetID
          amount
          sortAmount
        }
        paid
      }
      repay {
        balanceIn {
          assetID
          amount
          sortAmount
        }
      }
      supply {
        balanceIn {
          assetID
          amount
          sortAmount
        }
      }
      swap {
        balanceIn {
          assetID
          amount
          sortAmount
        }
        borrowed {
          assetID
          amount
          sortAmount
        }
        balanceOut {
          assetID
          amount
          sortAmount
        }
        price
        orderID
      }
      txID
      type
      userUID
      withdraw {
        balanceOut {
          assetID
          amount
          sortAmount
        }
      }
      addLiquidity {
        balancesIn {
          assetID
          amount
          sortAmount
        }
        sharesOut {
          assetID
          amount
          sortAmount
        }
      }
      externalDeposit {
        balanceIn {
          assetID
          amount
          sortAmount
        }
        txHex
      }
      externalWithdraw {
        balanceOut {
          assetID
          amount
          sortAmount
        }
        txHex
      }
      removeLiquidity {
        sharesIn {
          assetID
          amount
          sortAmount
        }
        balancesOut {
          assetID
          amount
          sortAmount
        }
      }
    }
  }
`
export const ModulePositionsDocument = gql`
  query ModulePositions {
    modulePositions {
      balance {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
      borrowed {
        assetID
        amount
        sortAmount
      }
      virtual {
        assetID
        amount
        sortAmount
      }
    }
  }
`
export const OrderBotsDocument = gql`
  query OrderBots($botId: String) {
    orderBots(botID: $botId) {
      botID
      name
      orderBookID
      strategy
      spread
      levels
      customTick
      minMult
      maxMult
      baseDecimals
      quoteDecimals
      startingBaseAmount
      frequency
      amountList
      priceList
      createdAt
      updatedAt
      updatedBy
    }
  }
`
export const TraderBotsDocument = gql`
  query TraderBots($botId: String) {
    traderBots(botID: $botId) {
      botID
      name
      orderBookID
      minSwapAmount
      maxSwapAmount
      orderQuantity
      swapQuantity
      bidExePercentage
      orderInterval
      minDelayBetweenOrders
      strategyName
      spread
      levels
      minTakePercentage
      maxTakePercentage
      maxBidPercentage
      dps
      minSkew
      maxSkew
      minBidPrice
      maxAskPrice
      tickSize
      amountMult
      targetPrice
      amountList
      deltaLevels {
        delta
        level
      }
      deltaMults {
        delta
        mult
      }
      option
      marketOrderFilePath
      createdAt
      updatedAt
      updatedBy
    }
  }
`
export const GetAllOrderBooksDocument = gql`
  query GetAllOrderBooks {
    getAllOrderBooks {
      orderBookID
      baseUID
      quoteUID
      tickSize
      priceDisplayMultiplier
      orders {
        orderBookID
        assetInID
        assetOutID
        amountIn
        amountOut
        price
        type
      }
    }
  }
`
export const OrderBookOpenOrdersDocument = gql`
  query OrderBookOpenOrders($orderBookId: String!) {
    orderBookOpenOrders(orderBookID: $orderBookId) {
      buys {
        price
        size
      }
      sells {
        price
        size
      }
    }
  }
`
export const UserLeveragePositionsDocument = gql`
  query UserLeveragePositions {
    userLeveragePositions {
      borrowed {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
    }
  }
`
export const GetAllPoolsDocument = gql`
  query GetAllPools($pagination: Pagination) {
    getAllPools(pagination: $pagination) {
      uid
      type
      createdAt
      baseAsset {
        assetID
        amount
        sortAmount
      }
      quoteAsset {
        assetID
        amount
        sortAmount
      }
      poolShares {
        assetID
        amount
        sortAmount
      }
      yieldSpaceProp {
        g
        maturityAt
      }
      prodProp {
        swapFee
      }
    }
  }
`
export const GetPoolOrdersSummaryDocument = gql`
  query GetPoolOrdersSummary($orderBookId: String!) {
    getPoolOrdersSummary(orderBookID: $orderBookId) {
      midPrice
      orderBookID
      spread
    }
  }
`
export const GetTradesDocument = gql`
  query GetTrades($assetInUid: String!, $assetOutUid: String!, $all: Boolean!, $pagination: Pagination) {
    getTrades(assetInUID: $assetInUid, assetOutUID: $assetOutUid, all: $all, pagination: $pagination) {
      uid
      assetInUID
      assetOutUID
      amountIn
      amountOut
      priceInToOut
      priceOutToIn
      match
      createdAt
      poolUID
      userAssetInUID
      orderBookUID
      userAssetOutUID
      txID
    }
  }
`
export const InitUserDocument = gql`
  query InitUser {
    initUser {
      uid
      email
      name
      photoURL
      provider
      providerID
      roles
      registrationDate
      lastLoginDate
      accountClosed
      timezone
      currency
      noOfAddFundsTxs
      wallets {
        address
        chainID
      }
    }
  }
`
export const GetAllUsersDocument = gql`
  query GetAllUsers {
    getAllUsers {
      uid
      roles
      name
      email
      provider
      registrationDate
      lastLoginDate
      balances {
        assetID
        amount
        sortAmount
      }
      borrowed {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
    }
  }
`
export const UpdateUsersRolesDocument = gql`
  mutation UpdateUsersRoles($updatedRoles: UpdateUserRolesInput) {
    UpdateUsersRoles(updatedRoles: $updatedRoles) {
      uid
      roles
    }
  }
`
export const UserOrderBookOrdersDocument = gql`
  query UserOrderBookOrders($orderBookId: String!) {
    userOrderBookOrders(orderBookID: $orderBookId) {
      advancedType
      amountIn
      amountOut
      assetInID
      assetOutID
      createdAt
      inFilled
      inverseLeverage
      orderBookID
      orderID
      outFilled
      price
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      state
      triggerPrice
      type
      updatedAt
      userUID
    }
  }
`
export const UserBalancesDocument = gql`
  query UserBalances {
    userBalances {
      total {
        assetID
        amount
        sortAmount
      }
      available {
        assetID
        amount
        sortAmount
      }
      locked {
        assetID
        amount
        sortAmount
      }
      borrowed {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
    }
  }
`
export const GetAllUserPositionsDocument = gql`
  query GetAllUserPositions($userUid: String!) {
    getAllUserPositions(userUID: $userUid) {
      total {
        assetID
        amount
        sortAmount
      }
      available {
        assetID
        amount
        sortAmount
      }
      locked {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
      borrowed {
        assetID
        amount
        sortAmount
      }
    }
  }
`
export const UserLeverageValueDocument = gql`
  query UserLeverageValue($userUid: String!) {
    userLeverageValue(userUID: $userUid) {
      supplied
      collateral
      borrowed
      borrowLimit
      liquidationThreshold
    }
  }
`
export const EstimateGasDocument = gql`
  query EstimateGas($estimateGasInput: ExternalWithdrawInput) {
    estimateGas(estimateGasInput: $estimateGasInput)
  }
`
export const OrderBookUpdatedDocument = gql`
  subscription OrderBookUpdated($orderBookId: String!) {
    orderBookUpdated(orderBookID: $orderBookId) {
      sells {
        price
        size
      }
      buys {
        price
        size
      }
    }
  }
`
export const UserOrderChangedDocument = gql`
  subscription UserOrderChanged($orderBookId: String!, $userUid: String!) {
    userOrderChanged(orderBookID: $orderBookId, userUID: $userUid) {
      advancedType
      amountIn
      amountOut
      assetInID
      assetOutID
      createdAt
      inFilled
      inverseLeverage
      orderBookID
      orderID
      outFilled
      price
      sortAmountIn
      sortAmountOut
      sortInFilled
      sortOutFilled
      sortRemainingAmountIn
      state
      triggerPrice
      type
      updatedAt
      userUID
    }
  }
`
export const CandlesUpdatedDocument = gql`
  subscription CandlesUpdated($marketId: String!, $resolution: String!) {
    candlesUpdated(marketID: $marketId, resolution: $resolution) {
      close
      high
      low
      open
      time
      volume
    }
  }
`
export const PoolBalancesUpdatedDocument = gql`
  subscription PoolBalancesUpdated {
    poolBalancesUpdated {
      uid
      type
      createdAt
      baseAsset {
        assetID
        amount
        sortAmount
      }
      quoteAsset {
        assetID
        amount
        sortAmount
      }
      poolShares {
        assetID
        amount
        sortAmount
      }
      yieldSpaceProp {
        g
        maturityAt
      }
      prodProp {
        swapFee
      }
    }
  }
`
export const UserBalancesUpdatedDocument = gql`
  subscription UserBalancesUpdated($userUid: String!) {
    userBalancesUpdated(userUID: $userUid) {
      total {
        assetID
        amount
        sortAmount
      }
      available {
        assetID
        amount
        sortAmount
      }
      locked {
        assetID
        amount
        sortAmount
      }
      borrowed {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action()

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateAsset(
      variables?: CreateAssetMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateAssetMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateAssetMutation>(CreateAssetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateAsset',
        'mutation',
        variables,
      )
    },
    UpdateAsset(
      variables?: UpdateAssetMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAssetMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateAssetMutation>(UpdateAssetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateAsset',
        'mutation',
        variables,
      )
    },
    UpsertOrderBot(
      variables: UpsertOrderBotMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpsertOrderBotMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpsertOrderBotMutation>(UpsertOrderBotDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpsertOrderBot',
        'mutation',
        variables,
      )
    },
    StartOrderBot(
      variables: StartOrderBotMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartOrderBotMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StartOrderBotMutation>(StartOrderBotDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'StartOrderBot',
        'mutation',
        variables,
      )
    },
    StopOrderBot(
      variables: StopOrderBotMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StopOrderBotMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StopOrderBotMutation>(StopOrderBotDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'StopOrderBot',
        'mutation',
        variables,
      )
    },
    StartTraderBot(
      variables: StartTraderBotMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StartTraderBotMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StartTraderBotMutation>(StartTraderBotDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'StartTraderBot',
        'mutation',
        variables,
      )
    },
    StopTraderBot(
      variables: StopTraderBotMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<StopTraderBotMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StopTraderBotMutation>(StopTraderBotDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'StopTraderBot',
        'mutation',
        variables,
      )
    },
    UpsertTraderBot(
      variables: UpsertTraderBotMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpsertTraderBotMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpsertTraderBotMutation>(UpsertTraderBotDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpsertTraderBot',
        'mutation',
        variables,
      )
    },
    CreateOrderWithPrice(
      variables?: CreateOrderWithPriceMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateOrderWithPriceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateOrderWithPriceMutation>(CreateOrderWithPriceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateOrderWithPrice',
        'mutation',
        variables,
      )
    },
    CreateAdvancedOrder(
      variables?: CreateAdvancedOrderMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateAdvancedOrderMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateAdvancedOrderMutation>(CreateAdvancedOrderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateAdvancedOrder',
        'mutation',
        variables,
      )
    },
    CancelOrder(
      variables: CancelOrderMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CancelOrderMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelOrderMutation>(CancelOrderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CancelOrder',
        'mutation',
        variables,
      )
    },
    CreatePool(
      variables?: CreatePoolMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreatePoolMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePoolMutation>(CreatePoolDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreatePool',
        'mutation',
        variables,
      )
    },
    AddLiquidityToPool(
      variables?: AddLiquidityToPoolMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddLiquidityToPoolMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddLiquidityToPoolMutation>(AddLiquidityToPoolDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddLiquidityToPool',
        'mutation',
        variables,
      )
    },
    RemoveLiquidityFromPool(
      variables?: RemoveLiquidityFromPoolMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveLiquidityFromPoolMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveLiquidityFromPoolMutation>(RemoveLiquidityFromPoolDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RemoveLiquidityFromPool',
        'mutation',
        variables,
      )
    },
    AddFundsToUser(
      variables?: AddFundsToUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddFundsToUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddFundsToUserMutation>(AddFundsToUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddFundsToUser',
        'mutation',
        variables,
      )
    },
    UpdateUser(
      variables?: UpdateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserMutation>(UpdateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateUser',
        'mutation',
        variables,
      )
    },
    AddBalances(
      variables?: AddBalancesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddBalancesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddBalancesMutation>(AddBalancesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddBalances',
        'mutation',
        variables,
      )
    },
    RemoveBalances(
      variables?: RemoveBalancesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveBalancesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveBalancesMutation>(RemoveBalancesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RemoveBalances',
        'mutation',
        variables,
      )
    },
    Supply(variables: SupplyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SupplyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SupplyMutation>(SupplyDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'Supply',
        'mutation',
        variables,
      )
    },
    Collateralize(
      variables: CollateralizeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CollateralizeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CollateralizeMutation>(CollateralizeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Collateralize',
        'mutation',
        variables,
      )
    },
    Withdraw(
      variables: WithdrawMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<WithdrawMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WithdrawMutation>(WithdrawDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Withdraw',
        'mutation',
        variables,
      )
    },
    Repay(variables: RepayMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RepayMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RepayMutation>(RepayDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'Repay',
        'mutation',
        variables,
      )
    },
    AddBalanceToUser(
      variables?: AddBalanceToUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddBalanceToUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddBalanceToUserMutation>(AddBalanceToUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddBalanceToUser',
        'mutation',
        variables,
      )
    },
    RemoveBalanceFromUser(
      variables?: RemoveBalanceFromUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveBalanceFromUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveBalanceFromUserMutation>(RemoveBalanceFromUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RemoveBalanceFromUser',
        'mutation',
        variables,
      )
    },
    DeleteMe(
      variables?: DeleteMeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteMeMutation>(DeleteMeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'DeleteMe',
        'mutation',
        variables,
      )
    },
    ExternalDeposit(
      variables: ExternalDepositMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExternalDepositMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExternalDepositMutation>(ExternalDepositDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ExternalDeposit',
        'mutation',
        variables,
      )
    },
    ExternalWithdraw(
      variables: ExternalWithdrawMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExternalWithdrawMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExternalWithdrawMutation>(ExternalWithdrawDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ExternalWithdraw',
        'mutation',
        variables,
      )
    },
    GetAsset(variables: GetAssetQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAssetQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAssetQuery>(GetAssetDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetAsset',
        'query',
        variables,
      )
    },
    GetAllAssets(
      variables: GetAllAssetsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllAssetsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllAssetsQuery>(GetAllAssetsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllAssets',
        'query',
        variables,
      )
    },
    GetCandles(
      variables: GetCandlesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCandlesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCandlesQuery>(GetCandlesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetCandles',
        'query',
        variables,
      )
    },
    GetAppEnv(
      variables?: GetAppEnvQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAppEnvQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAppEnvQuery>(GetAppEnvDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetAppEnv',
        'query',
        variables,
      )
    },
    ExploreTxs(
      variables?: ExploreTxsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExploreTxsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExploreTxsQuery>(ExploreTxsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ExploreTxs',
        'query',
        variables,
      )
    },
    ModulePositions(
      variables?: ModulePositionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ModulePositionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ModulePositionsQuery>(ModulePositionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ModulePositions',
        'query',
        variables,
      )
    },
    OrderBots(
      variables?: OrderBotsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<OrderBotsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderBotsQuery>(OrderBotsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'OrderBots',
        'query',
        variables,
      )
    },
    TraderBots(
      variables?: TraderBotsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TraderBotsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TraderBotsQuery>(TraderBotsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'TraderBots',
        'query',
        variables,
      )
    },
    GetAllOrderBooks(
      variables?: GetAllOrderBooksQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllOrderBooksQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllOrderBooksQuery>(GetAllOrderBooksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllOrderBooks',
        'query',
        variables,
      )
    },
    OrderBookOpenOrders(
      variables: OrderBookOpenOrdersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<OrderBookOpenOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderBookOpenOrdersQuery>(OrderBookOpenOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'OrderBookOpenOrders',
        'query',
        variables,
      )
    },
    UserLeveragePositions(
      variables?: UserLeveragePositionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserLeveragePositionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserLeveragePositionsQuery>(UserLeveragePositionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserLeveragePositions',
        'query',
        variables,
      )
    },
    GetAllPools(
      variables?: GetAllPoolsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllPoolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllPoolsQuery>(GetAllPoolsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllPools',
        'query',
        variables,
      )
    },
    GetPoolOrdersSummary(
      variables: GetPoolOrdersSummaryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetPoolOrdersSummaryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPoolOrdersSummaryQuery>(GetPoolOrdersSummaryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetPoolOrdersSummary',
        'query',
        variables,
      )
    },
    GetTrades(
      variables: GetTradesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTradesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTradesQuery>(GetTradesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'GetTrades',
        'query',
        variables,
      )
    },
    InitUser(variables?: InitUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InitUserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InitUserQuery>(InitUserDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'InitUser',
        'query',
        variables,
      )
    },
    GetAllUsers(
      variables?: GetAllUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllUsersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllUsersQuery>(GetAllUsersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllUsers',
        'query',
        variables,
      )
    },
    UpdateUsersRoles(
      variables?: UpdateUsersRolesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUsersRolesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUsersRolesMutation>(UpdateUsersRolesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateUsersRoles',
        'mutation',
        variables,
      )
    },
    UserOrderBookOrders(
      variables: UserOrderBookOrdersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserOrderBookOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserOrderBookOrdersQuery>(UserOrderBookOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserOrderBookOrders',
        'query',
        variables,
      )
    },
    UserBalances(
      variables?: UserBalancesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserBalancesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserBalancesQuery>(UserBalancesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserBalances',
        'query',
        variables,
      )
    },
    GetAllUserPositions(
      variables: GetAllUserPositionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAllUserPositionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAllUserPositionsQuery>(GetAllUserPositionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetAllUserPositions',
        'query',
        variables,
      )
    },
    UserLeverageValue(
      variables: UserLeverageValueQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserLeverageValueQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserLeverageValueQuery>(UserLeverageValueDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserLeverageValue',
        'query',
        variables,
      )
    },
    EstimateGas(
      variables?: EstimateGasQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EstimateGasQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EstimateGasQuery>(EstimateGasDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'EstimateGas',
        'query',
        variables,
      )
    },
    OrderBookUpdated(
      variables: OrderBookUpdatedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<OrderBookUpdatedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderBookUpdatedSubscription>(OrderBookUpdatedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'OrderBookUpdated',
        'subscription',
        variables,
      )
    },
    UserOrderChanged(
      variables: UserOrderChangedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserOrderChangedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserOrderChangedSubscription>(UserOrderChangedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserOrderChanged',
        'subscription',
        variables,
      )
    },
    CandlesUpdated(
      variables: CandlesUpdatedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CandlesUpdatedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CandlesUpdatedSubscription>(CandlesUpdatedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CandlesUpdated',
        'subscription',
        variables,
      )
    },
    PoolBalancesUpdated(
      variables?: PoolBalancesUpdatedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PoolBalancesUpdatedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PoolBalancesUpdatedSubscription>(PoolBalancesUpdatedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'PoolBalancesUpdated',
        'subscription',
        variables,
      )
    },
    UserBalancesUpdated(
      variables: UserBalancesUpdatedSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserBalancesUpdatedSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserBalancesUpdatedSubscription>(UserBalancesUpdatedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserBalancesUpdated',
        'subscription',
        variables,
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
