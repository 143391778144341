import { Navigate, useNavigate } from 'react-router-dom'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'
import { useOrderBooks } from '@/hooks/useOrderBooks'
import { useUserBalanceSubscription } from '@/hooks/useUserBalanceSubscription'
import { queryClient } from '@/services/api'
import { getSdk } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useEffect, useState } from 'react'
import { LoadingSpinner } from '@/components/Loading/LoadingSpinner'
import { getAuth, signOut } from 'firebase/auth'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { usePoolBalanceSubscription } from '@/hooks/usePoolBalanceUpdateSubscription'

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const user = useFirebaseAuth()

  useUserBalanceSubscription()
  usePoolBalanceSubscription()
  useOrderBooks()

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        signOut(getAuth()).finally(() => {
          queryClient.clear()
        })
        createNotification(
          'API',
          'Unable to retrieve user information.',
          NotificationType.SIMPLE,
          NotificationStatus.ERROR,
        )
        navigate('/auth/sign-in', { replace: true })
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [loading])

  useEffect(() => {
    ;(async () => {
      if (!user) return
      try {
        setLoading(true)
        const { initUser } = await queryClient.ensureQueryData({
          queryKey: ['USER'],
          queryFn: () => getSdk(graphQLClient).InitUser(),
        })
        setLoggedIn(!!initUser)
      } catch (error) {
        console.log(error)
        setLoggedIn(false)
      } finally {
        setLoading(false)
      }
    })()
  }, [])
  if (loading)
    return (
      <div className='flex h-screen'>
        <div className='m-auto'>
          <LoadingSpinner className='' />
        </div>
      </div>
    )

  if (loggedIn) return <>{children}</>

  return <Navigate to='/auth/sign-in' replace={true} />
}
