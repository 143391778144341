export const USDCId = 'USDC'
export const USDCDecimals = 6

export const USDC: any = {
  1: {
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 6,
  },
  42161: {
    address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    decimals: 6,
  },
}

export const depositAddress: any = {
  1: '0xFDfcA65738aeddaA6abF1326BA6F9a0b2e0caCA5',
  42161: '0xFDfcA65738aeddaA6abF1326BA6F9a0b2e0caCA5',
}
