import { Asset, OrderBook } from '@/graphql/generated/graphql-request'

interface BondDetailsInfoProps {
  baseAsset: Asset
  orderBook: OrderBook | undefined
  top?: boolean
}

export const BondDetailsInfo = ({ baseAsset, orderBook, top }: BondDetailsInfoProps) => {
  if (!baseAsset || !orderBook) {
    return <div>No pair selected</div>
  }

  return (
    <div
      className={`w-full h-fit flex flex-col col-start-3 col-span-1 ${top ? 'justify-start' : 'justify-center'} items-start gap-4`}
    >
      <div className={'w-full text-end font-bold text-white text-xl pb-4'}>
        CUSIP: {baseAsset?.bond?.cusip ?? 'n/a'}
      </div>
      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Ticker:</div>
        <div className={'text-white font-semibold'}>{baseAsset?.bond?.ticker ?? 'n/a'}</div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Type:</div>
        <div className={'text-white font-semibold'}>{baseAsset?.bond?.assetBondType ?? 'n/a'}</div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Tick Size:</div>
        <div className={'text-white font-semibold'}>${orderBook?.tickSize ?? 'n/a'}</div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Step Size:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'text-white font-semibold'}>{orderBook?.tickSize ?? 'n/a'}</div>
          <div className={'text-white rounded-md bg-dark font-semibold px-2'}>{baseAsset?.symbol ?? 'n/a'}</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Minimum Order Size:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'text-white font-semibold'}>{orderBook?.tickSize ?? 'n/a'}</div>
          <div className={'text-white rounded-md bg-dark font-semibold px-2'}>{baseAsset?.symbol ?? 'n/a'}</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Maximum Leverage:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'text-white font-semibold'}>10.00x</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between border-b border-dark pb-2'}>
        <div className={'text-white-dark font-semibold'}>Maintenance Margin Fraction:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'text-white font-semibold'}>7.000%</div>
        </div>
      </div>

      <div className={'w-full flex flex-row px-1 justify-between pb-2'}>
        <div className={'text-white-dark font-semibold'}>Initial Margin Fraction:</div>
        <div className={'flex flex-row gap-2'}>
          <div className={'text-white font-semibold'}>10.000%</div>
        </div>
      </div>
    </div>
  )
}
