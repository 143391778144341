import React from 'react'
import { AssetBondType, AssetType, CouponFrequency } from '@/graphql/generated/graphql-request'
import { ValidationSchema } from './CreateOrUpdateAsset'
import { PRICE_DECIMALS } from '@/utils/constants'

interface BondFormProps {
  errors: any
  register: any
  handleInputChange: (e: React.FormEvent<HTMLInputElement>, field: keyof ValidationSchema, decimals: number) => void
  defaultValues: Partial<ValidationSchema>
}

export const BondForm = ({ register, handleInputChange, defaultValues }: BondFormProps) => {
  return (
    <div className='panel dark:bg-card grid grid-cols-4 grid-rows-5 gap-2 h-full'>
      <div className='flex flex-col col-start-1 row-start-1'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Name</div>
          <input
            id={'name'}
            {...register('name', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-2 row-start-1'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Issuer</div>
          <input
            id={'issuer'}
            {...register('issuer', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-3 row-start-1'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>ISIN</div>
          <input
            id={'isin'}
            {...register('isin', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-4 row-start-1'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Yield</div>
          <input
            id={'yield'}
            onInput={(e) => handleInputChange(e, 'yield', PRICE_DECIMALS)}
            {...register('yield', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-1 row-start-2'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Coupon Start Date</div>
          <input
            id={'couponStartDate'}
            type='date'
            {...register('couponStartDate', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-2 row-start-2'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Coupon Final Date</div>
          <input
            id={'finalCouponDate'}
            type='date'
            {...register('finalCouponDate', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-3 row-start-2'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Issue date</div>
          <input
            id={'issueDate'}
            type={'date'}
            {...register('issueDate', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-4 row-start-2'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Maturity Date</div>
          <input
            id={'maturityDate'}
            type={'date'}
            {...register('maturityDate', { required: false })}
            defaultValue={defaultValues.maturityDate}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-1 row-start-3'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Asset Bond Type</div>
          <select
            {...register('assetBondType', { required: false })}
            className='relative rounded-sm shadow-sm appearance-none form-input py-1 peer placeholder:tracking-widest border valid:border-dark text-right outline-none focus:ring-0 focus:ring-opacity-0'
          >
            {Object.values(AssetBondType).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='flex flex-col col-start-2 row-start-3'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Coupon</div>
          <input
            id={'coupon'}
            onInput={(e) => handleInputChange(e, 'coupon', PRICE_DECIMALS)}
            {...register('coupon', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-3 row-start-3'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Coupon Frequency</div>
          <select
            {...register('couponFrequency', { required: false })}
            className='relative rounded-sm shadow-sm appearance-none form-input py-1 peer placeholder:tracking-widest border valid:border-dark text-right outline-none focus:ring-0 focus:ring-opacity-0'
          >
            {Object.values(CouponFrequency).map((frequency) => (
              <option key={frequency} value={frequency}>
                {frequency}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='flex flex-col col-start-4 row-start-3'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>CUSIP</div>
          <input
            id={'cusip'}
            {...register('cusip', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-3 row-start-4'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Ticker</div>
          <input
            id={'ticker'}
            {...register('ticker', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-4 row-start-4'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Payments per year</div>
          <input
            id={'numberOfPaymentsPerYear'}
            onInput={(e) => handleInputChange(e, 'numberOfPaymentsPerYear', PRICE_DECIMALS)}
            {...register('numberOfPaymentsPerYear', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-1 row-start-4 col-span-2'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Bond details url</div>
          <input
            id={'bondDetailsUrl'}
            {...register('bondDetailsUrl', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-1 row-start-5 col-span-2'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Education url</div>
          <input
            id={'educationUrl'}
            {...register('educationUrl', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>

      <div className='flex flex-col col-start-3 row-start-5 col-span-2'>
        <div className='relative flex flex-col w-full'>
          <div className='py-1 whitespace-nowrap text-left'>Price history url</div>
          <input
            id={'priceHistoryUrl'}
            {...register('priceHistoryUrl', { required: false })}
            className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
          />
        </div>
      </div>
    </div>
  )
}
